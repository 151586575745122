////////////////////
// COLORED DATAPICKER - WHITE
////////////////////////////////////////////////////////////////////////////////
.white-datepicker {
  @apply text-white {}
  @apply font-sans {}

  .datepicker {
    @apply bg-primary {}

    @screen md {
      @apply bg-transparent {} }

    &__dummy-wrapper {
      @apply border-2 {}
      @apply border-white {}
      @apply text-white {} }

    &__wrapper {
      @apply bg-transparent {} }

    &__close-button {
      &::before {
        @apply text-white {} } }

    &__input {
      @apply border-white #{!important} {} }

    &__month-day {
      @apply bg-transparent {}
      @apply text-white {}
      @apply border-transparent #{!important} {}
      @apply shadow-none #{!important} {}

      &:hover {
        @apply bg-secondary {}
        @apply text-white {} } }

    &__month-day.datepicker__month-day--valid:not(.datepicker__month-day--selected) {
      &:hover {
        @apply bg-transparent {}
        @apply text-white {} }

      &::after {
        background-image: url('../../frontend/images/datepicker/check-in.svg');
        opacity: 0; }

      &:hover::after {
        opacity: 1; } }

    &__month-day--disabled {
      color: #797a85 !important; }

    &__month-day--today {
      .datepicker__month-day-wrapper {
        @apply border-transparent {} } }

    &__month-day--selected {
      @apply font-sans #{!important} {}
      font-weight: 700 !important;
      text-transform: uppercase !important;
      @apply bg-secondary {}
      @apply text-white {} }

    &__month-day--first-day-selected,
    &__month-day--last-day-selected {
      background-color: transparent !important; }

    &__month-day--first-day-selected {
      &::after {
        background-image: url('../../frontend/images/datepicker/check-in.svg'); } }

    &__month-day--check-in-forbidden {
      &::after {
        background-image: url('../../frontend/images/datepicker/disable-check-in.svg');
        opacity: 0.5; } }

    &__month-day--last-day-selected {
      &::before {
        background-image: url('../../frontend/images/datepicker/check-out.svg'); } }

    &__month-day--check-out-forbidden {
      &::before {
        background-image: url('../../frontend/images/datepicker/disable-check-out.svg');
        opacity: 0.5; } }

    &__month-day--check-in-forbidden {
      &.datepicker__month-day--check-out-forbidden {
        background-color: rgba(#A5A7B5, 0.5);
        color: #797a85;

        &::after,
        &::before {
          @apply hidden {} } } }

    &__month-day--check-in-forbidden,
    &__month-day--check-out-forbidden {
      @apply bg-transparent {} }

    &__month-name {
      @apply text-white {} }

    &__week-row {
      @apply bg-primary {}

      @screen md {
        @apply bg-transparent {} } }

    &__week-name {
      @apply text-white {} }

    &__month-button {
      &::after {
        @apply text-secondary {} } }

    &__input {
      @apply text-white {} }

    &__wrapper {
      &::after {
        @apply text-white {} } }

    &__close-button {
      &::before {
        @apply text-white {}

        @screen md {
          @apply text-primary {} } } }

    button.next--mobile {
      @apply bg-white {}

      &:after {
        @apply text-primary {} } } } }

////////////////////
// COLORED DATEPICKER - BLUE
////////////////////////////////////////////////////////////////////////////////
.primary-datepicker {
  @apply text-primary {}
  @apply font-sans {}

  .datepicker {
    @apply bg-white-smooth {}

    &__dummy-wrapper {
      @apply border-2 {}
      @apply border-primary {} }

    &__inner {
      box-shadow: 0px 5px 4px 0px rgba(0,0,0,0.14);

      @screen md {
        @apply border-2 {}
        @apply border-primary {}
        @apply shadow-none {} } }

    &__wrapper {
      @apply bg-transparent {} }

    &__month-day--today {
      .datepicker__month-day-wrapper {
        @apply border-transparent {} } }

    &__month-day {
      @apply bg-transparent {}
      @apply text-primary {}
      @apply border-transparent #{!important} {}
      @apply shadow-none #{!important} {}

      &:hover {
        @apply bg-secondary {}
        @apply text-primary {} } }

    &__month-day.datepicker__month-day--valid:not(.datepicker__month-day--selected) {
      &:hover {
        @apply bg-transparent {}
        @apply text-primary {} }

      &::after {
        background-image: url('../../frontend/images/datepicker/check-in.svg');
        opacity: 0; }

      &:hover::after {
        opacity: 1; } }

    &__month-day--disabled {
      color: #797a85 !important; }

    &__month-day--first-day-selected,
    &__month-day--last-day-selected {
      @apply bg-transparent #{!important} {} }

    &__month-day--first-day-selected {
      &::after {
        background-image: url('../../frontend/images/datepicker/check-in.svg'); } }

    &__month-day--last-day-selected {
      &::before {
        background-image: url('../../frontend/images/datepicker/check-out.svg'); } }

    &__month-day--check-in-forbidden {
      &::after {
        background-image: url('../../frontend/images/datepicker/disable-check-in.svg');
        opacity: 0.5; } }

    &__month-day--check-out-forbidden {
      &::before {
        background-image: url('../../frontend/images/datepicker/disable-check-out.svg');
        opacity: 0.5; } }

    &__month-day--check-in-forbidden {
      &.datepicker__month-day--check-out-forbidden {
        background-color: rgba(#A5A7B5, 0.5);
        color: #797a85;

        &::after,
        &::before {
          @apply hidden {} } } }

    &__month-day--check-in-forbidden,
    &__month-day--check-out-forbidden {
      @apply bg-transparent {} }

    &__month-day--selected {
      @apply font-sans #{!important} {}
      @apply bg-secondary {}
      font-weight: 700 !important;
      text-transform: uppercase !important; }

    &__month-name {
      @apply text-primary {} }

    &__week-row {
      @apply border-primary {}
      @apply bg-white-smooth {}

      @screen md {
       @apply border-transparent {} } }

    &__week-name {
      @apply text-primary {} }

    &__month-button {
      &::after {
        @apply text-secondary {} } }

    &__wrapper {
      &::after {
        @apply text-primary {} } }

    &__close-button {
      &::before {
        @apply text-primary {} } }

    button.next--mobile {
      @apply bg-primary {}

      &:after {
        @apply text-white {} } } } }
