// PROJECT - Page BLOG - Studio HB 2017

////////////////////
// BLOG
////////////////////////////////////////////////////////////////////////////////
.blog-time,
.blog-category {
  color: $gray; }


.blog-category {
  font-size: 1.2rem;
  text-transform: uppercase; }


.blog-time {
  font-size: 1.4rem; }


.blog-nav__title {
  font-size: 1.5rem;
  margin-top: 1rem; //-> thumb margin-top
  margin-bottom: 0.5rem; }





////////////////////
// BLOG SHOW
////////////////////////////////////////////////////////////////////////////////
.blog-show__title {
  max-width: 100%;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  width: 62rem; }


.blog-show__image {
  background-color: $gray-xlight;
  margin-bottom: 4rem;
  text-align: center; }
