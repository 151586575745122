// PROJECT - Component FORM VALIDATION - Studio HB 2017

////////////////////
// FORM VALIDATION
////////////////////////////////////////////////////////////////////////////////

// REQUIRED
// ---------------------------------------------------------
.required {
  label::after {
    content: " *"; }

  // override custom checkbox/radio
  .abc-radio {
    label::after {
      content: " "; } }

  .abc-checkbox {
    input:not(:focus) {
      + label::after {
        content: none; } } } }


// ERROR EXPLANATION
// ---------------------------------------------------------
.error_explanation {
  background: lighten($brand-danger,  25%);
  padding: 1.2rem;
  border-radius: 0;
  margin-bottom: 15px;

  p, ul {
    margin-bottom: 0; }

  ul {
    padding-left: 0;
    list-style: inside; } }


// FIELD ERROR
// ---------------------------------------------------------
///// LABEL & LABEL SMOOTH
.form-label.field_with_errors,
.label--smooth.field_with_errors,
.input--smooth:focus + .label--smooth.field_with_errors,
.input--filled + .label--smooth.field_with_errors {
  color: $red; }

.label--smooth.field_with_errors {
  &::before {
    background-color: $red; } }

///// FORM CONTROL (INPUT / TEXTAREA)
.form-control.field_with_errors {
  border-color: $red; }


///// CUSTOM CHECKOBX RADIO
.abc-radio,
.abc-checkbox {
  label.field_with_errors {
    color: $red;
    &::before {
      border-color: $red; } } }


.abc-checkbox {
  input[type="checkbox"],
  input[type="radio"] {
    &:checked {
      + label.field_with_errors {
        &::before {
          border-color: $red;
          background-color: $red; } } } } }


.abc-radio {
  input[type="checkbox"],
  input[type="radio"] {
    + label.field_with_errors::after {
      background-color: $red; }

    &:checked {
      + label.field_with_errors {
        &::after {
          background-color: $red; }

        &::before {
          border-color: $red; } } } } }




