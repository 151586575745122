// PROJECT - Override-vendors MULTISELECT - Studio HB 2019

////////////////////
// MULTISELECT
////////////////////////////////////////////////////////////////////////////////
.searchbar__multiselect {
  margin-bottom: 2.6rem; }
$multiselect-height: $input-height;
.main-wrapper .multiselect {
  $class: 'multiselect';
  cursor: pointer;
  min-height: $multiselect-height;

  &:hover {
    .#{$class}__placeholder {
      color: $primary; } }


  &--active {
    .#{$class}__select {
      transform: none;
      &::before {
        transform: rotate(180deg); } } }

  &,
  &__input,
  &__single,
  &__tags,
  &__placeholder {
    background-color: $white-smooth;
    font-size: 1.8rem;
    font-weight: 500; }

  &__content-wrapper {
    background-color: $white-smooth;
    border: 0.2rem solid $primary;
    border-radius: 0;
    left: -0.1rem; }

  &__input,
  &__placeholder,
  &__single {
    padding-left: 0;
    padding-top: 0;
    margin-bottom: 0;
    min-height: 1rem; }

  &__placeholder {
    display: block !important;

    color: $primary;
    opacity: 1;
    vertical-align: bottom;
    line-height: 1.2;
    transition: all $transition-default; }


  &__tags {
    box-shadow: none;
    padding: 0 4.2rem 0 1.3rem;
    padding-bottom: 0;
    width: 100%;
    overflow: hidden;
    border: 0;
    border-radius: 0 !important;
    display: flex;
    align-items: center;
    min-height: $multiselect-height;
    &-wrap {
      flex-wrap: wrap;
      display: flex;
      align-items: center; } }

  &__tag {
    height: 1.2rem;
    color: $primary;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    background-color: $white-smooth;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0;
    &-icon {
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      &::after {
        color: $gray;
        transition: all $transition-default; }
      &:hover {
        background-color: $red;
        &::after {
          color: $white; } } } }

  &__select {
    margin-bottom: 2.6rem;
    height: $multiselect-height;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0;
    border: 0.2rem solid $primary;

    padding-right: 1rem;
    &::before {
      content: '\f0dd';
      color: $primary;
      font-weight: bold;
      font-family: var(--font-family-icon);
      top: -0.25rem;
      border: none;
      position: absolute;
      right: 2.1rem;
      width: 3rem;
      margin-top: 0;
      height: $multiselect-height;
      width: auto;
      transition: all $transition-default;
      display: flex;
      align-items: center;
      justify-content: center; } }


  &__content-wrapper {
    color: $primary; }

  &__option {
    cursor: pointer;
    transition: all $transition-default;
    padding: 1.2rem;
    white-space: normal;
    color: $primary;
    &:hover {
      background-color: $primary;
      color: $white; }

    &--selected {
      background-color: $white;
      &:hover {
        background-color: $primary;
        color: $white; } }

    &--highlight {
      background-color: transparent;
      color: $primary;
      &:hover {
        background-color: $primary;
        color: $white; } }

    &.#{$class}__option--selected {
      background-color: $primary;
      color: $white; } } }


@media (max-width: theme('screens.md-max')) {
  .main-wrapper .multiselect {
    &__select {
      border-left: 0.3rem solid $primary; } } }



////////////////////
// WHITE MULTISELECT
////////////////////////////////////////////////////////////////////////////////

.white-multiselect {
  .multiselect {
    border-radius: 0;
    &:hover {
      .multiselect__placeholder {
        color:  $white-smooth; } }
    &,
    &__input,
    &__single,
    &__tags,
    &__placeholder {
      background-color: $primary; }

    &__single, {
      color: $white-smooth; }

    &__content-wrapper {
      background-color:  $primary;
      border: 0.2rem solid $white-smooth; }


    &__placeholder {
      color: $white-smooth; }



    &__tag {
      color: $white-smooth;
      background-color: $primary;
      &-icon {

        &::after {
          color: $white; }
        &:hover {
          background-color: $red;
          &::after {
            color: $white; } } } }

    &__select {

      border: 0.2rem solid $white-smooth;
      &::before {
        color: $white-smooth; } }

    &__content-wrapper {
      color: $white-smooth; }

    &__option {
      color: $white-smooth;
      &:hover {
        background-color: $primary; }

      &--selected {
        background-color: $primary;
        &:hover {
          background-color: $white-smooth;
          color: $primary; } }

      &--highlight {
        background-color: transparent;
        color: rgba($white-smooth,0.5);
        &:hover {
          background-color: $primary; } }
      &.multiselect__option--selected {
        background-color: $white;
        color:  $primary; } } } }


.reservation-multiselect {
  .multiselect {
    width: 17.7rem;
    height: 5.6rem; }

  .multiselect__select {
    border-width: 2px !important; } }


////////////////////
// BANNER MULTISELECT
////////////////////////////////////////////////////////////////////////////////

.banner-multiselect {
  .multiselect {
    max-width: 35rem;
    height: 6.7rem;
    border-radius: 0;
    margin-top: -13.5rem;
    &:hover {
      .multiselect__placeholder {
        color:  $white-smooth; } }

    &,
    &__input,
    &__tags {
      background-color: transparent; }

    &__placeholder {
      background-color: transparent; }

    &__single, {
      color: $white-smooth;
      background-color: transparent; }
    &__content-wrapper {
      background-color: $primary;
      border: 0.2rem solid $white-smooth; }


    &__placeholder {
      color: $white-smooth; }

    &__option--group {
      background-color: $primary !important;
      color: $white !important;
      font-weight: bold; }

    &__tag {
      color: $white-smooth;
      background-color: transparent;

      &-icon {

        &::after {
          color: $white; }
        &:hover {
          background-color: $red;
          &::after {
            color: $white; } } } }

    &__select {
      height: 100%;
      border: 0.2rem solid $white-smooth;
      &::before {
        color: $white-smooth; } }

    &__select,
    &__select::before,
    &__tags {
      height: 100%; }

    &__content-wrapper {
      color: $white-smooth; }

    &__option {
      color: $white-smooth;
      &:hover {
        background-color: #3A4386; }

      &--selected {
        background-color: $primary;
        &:hover {
          background-color: $white-smooth;
          color: #3A4386; } }

      &--highlight {
        background-color: transparent;
        color: rgba($white-smooth,1);
        &:hover {
          background-color: #3A4386; } }
      &.multiselect__option--selected {
        background-color: $secondary;
        color:  $primary; } } } }

@media (max-width: theme('screens.md-max')) {
  .banner-multiselect {

    .multiselect {
      height: 4.8rem;
      border-radius: 0;
      margin-top: 0rem;
      &:hover {
        .multiselect__placeholder {
          color:  $primary; } }
      &,
      &__input,
      &__tags {
        animation: none;
        background-color: $white-smooth; }

      &__placeholder {
        background-color: transparent; }
      &__single, {
        color: $primary;
        background-color: transparent; }
      &__content-wrapper {
        background-color: $white;
        border: 0.2rem solid $primary; }
      &__placeholder {
        color: $primary; }

      &__option--group {
        background-color: $white-smooth !important;
        color: $primary !important;
        font-weight: bold; }

      &__tag {
        color: $primary;
        animation: none;
        background-color: $white-smooth;
        &-icon {

          &::after {
            color: $primary; }
          &:hover {
            &::after {
              color: $primary; } } } }

      &__select {
        height: 100%;
        border: 0.2rem solid $primary;
        &::before {
          color: $primary; } }

      &__select,
      &__select::before,
      &__tags {
        height: 100%; }

      &__content-wrapper {
        color: $primary; }

      &__option {
        color: $primary;
        &:hover {
          background-color: $primary; }

        &--selected {
          background-color: $white-smooth;
          &:hover {
            background-color: $primary;
            color: $primary; } }

        &--highlight {
          background-color: transparent;
          color: rgba($primary,1);
          &:hover {
            background-color: $white-smooth; } }
        &.multiselect__option--selected {
          background-color: $primary;
          color:  $white-smooth; } } } } }
