////////////////////
// ICON GROUP NUMBER
////////////////////////////////////////////////////////////////////////////////
.icon-group-number {
  @apply text-center {}
  @apply text-lg {}
  @apply w-48 {}
  @apply leading-12 {}

  &__value {
    @apply flex {}
    @apply items-center {}
    @apply justify-center {}

    #{class-begin('fa')} {
      font-size: 2rem;
      @apply text-secondary {}
      @apply ml-3 {} }

    span {
      @apply text-xl {} } }

  &__text {
    @apply font-semibold {} } }





////////////////////
// ICON GROUP
////////////////////////////////////////////////////////////////////////////////
.icon-group__list {
  @apply flex {}
  @apply justify-center {}
  @apply flex-wrap {}
  @apply mx-auto {}
  .icon-group {
    @apply w-48 {} }

  @media (max-width: theme('screens.sm-max')) {
    width: 30rem;
    .icon-group {
      width: 10rem; } } }


.icon-group {
  @apply py-5 {}
  @apply text-center {}
  #{class-begin('fa')} {
    @apply text-2xl {}
    @apply text-secondary {}
    @apply mb-3 {} }

  @screen md {
    @apply py-12 {} } }




////////////////////
// ICON GROUP ACCORDION
////////////////////////////////////////////////////////////////////////////////
.icon-group__accordion {
  @apply h-0 {}
  @apply overflow-hidden {}
  transition: all ease-in-out 0.5s; }
.icon-group__accordion.active {
  transition: all ease-in-out 0.5s;
  height: 300px;
  @apply overflow-hidden {} }

