////////////////////
// LIST
////////////////////////////////////////////////////////////////////////////////

.line-list {
  @apply flex flex-wrap text-white text-lg mt-8 justify-center w-4/5 mx-auto {}
  li {
    @apply block mb-6 flex {} }
  li:before {
    content: '-';
    @apply block mx-6 {} }
  li:first-child:before {
    @apply hidden {} } }
