
.concept {
  &-road {
    @screen lg {
      &__illustration {
        @apply transform {}
        @apply translate-x-1/5 {}
        width: 45%; }

      &__content {
        @apply px-12 {}
        width: 55%; }

      &__path {
        .circle {
          opacity: 0; }
        .path {
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000; } } } }

  &-list {
    &__item {
      &::before {
        content: "\2022";
        color: $secondary;
        font-weight: bold;
        display: inline-block;
        width: 1.1em; } } }
  .icon-group__list {
    width: auto;
    img {
      height: 5rem; } }
  &-image {
    &__sticky {
      top: 0; } } }

.concept-road__odd {
  @screen lg {
    @apply transform {}
    @apply translate-x-1/5-r {} } }
