.quote-text {
  cursor: pointer;
  line-height: 1.7; }
.quote-show {
  .row {
    margin-left: 0;
    margin-right: 0; }
  .agile__dots {
    .agile__dot {
      height: 2rem;
      button {
        background-color: $secondary; } } }
  .agile__nav-button {
    display: none; } }

@screen sm {
  .quote-text {
    cursor: pointer;
    line-height: 1.2; } }
@screen lg {
  .quote-show {
    .agile {
      .agile__actions {
        margin: 0;
        position: static;
        .agile__nav-button {
          display: block;
          height: 100%;
          width: 65%;
          position: absolute;
          &::before {
            content: ''; } }
        .agile__nav-button--prev {
          left: -15%;
          top: 0;
          &:hover {
            cursor: url("../../frontend/images/icons/MOHOM-cursor-left.svg") 25 15, auto; } }
        .agile__nav-button--next {
          right: -15%;
          top: 0;
          &:hover {
            cursor: url("../../frontend/images/icons/MOHOM-cursor-right.svg") 25 15, auto; }
          &::before {
            content: ''; } } } } } }

////////////////////
// OPINION ITEM
////////////////////////////////////////////////////////////////////////////////
.opinion-item {
  @apply text-center {}
  &__address {
    @extend .h2;
    @apply font-title {}
    line-height: 6rem;
    margin-top: 0.9rem; } }


//// in a slider
.agile__slide {
  .address-item {
    .btn {
      @apply opacity-0 {}
      @appy transition-smooth {} } }

  &--active {
    .address-item {
      .btn {
        @apply opacity-100 {} } } } }
