// PROJECT - Component IMAGE - Studio HB 2017

////////////////////
// OBJECT FIT
////////////////////////////////////////////////////////////////////////////////

///// IMG COVER
.img-cover {
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover'; // for the polyfill object-fit cover
  width: 100%; }

.img-detail {
  picture {
    width: 100%;
    height: 100%;
    display: flex; }

  picture img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover'; // for the polyfill object-fit cover
    object-position: center; } }

@media (max-width: theme('screens.lg-max')) {
  .img-detail {
    picture img {
      height: 100%;
      object-fit: contain;
      font-family: 'object-fit: contain'; // for the polyfill object-fit contain
      width: 100%; } } }

///// IMG CONTAIN
.img-contain {
  height: 100%;
  object-fit: contain;
  font-family: 'object-fit: contain'; // for the polyfill object-fit contain
  width: 100%; }





////////////////////
// IMG FLUID
////////////////////////////////////////////////////////////////////////////////

///// IMG FLUID
.img-fluid {
  height: auto;
  max-width: 100%; }


///// IMG FULL
.img-full {
  height: auto;
  width: 100%; }

///// IMG ZOOM
.img-zoom {
  transform: scale(1);
  transition: .3s ease-in-out; }

.img-zoom:hover {
  transform: scale(1.1); }

////////////////////
// REMAINING RATIO
////////////////////////////////////////////////////////////////////////////////

// Permet au wrapper de l'image de conserver le même ratio quelle que soit la taille d'écran
// Le ratio de 60% correspond à une taille standard de photo horizontale (60% de hauteur par rapport à la largeur)
// Pour plus d'infos : http://www.mademyday.de/css-height-equals-width-with-pure-css.html

.img-ratio-wrapper {
  position: relative;
  &::before {
    content: '';
    display: block;
    padding-top: 60%; }

  .img-ratio {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; } }
