.searchbar {
  top: 10rem;
  @apply mx-auto {} }

.searchbar__submit {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 100%;
  @apply text-lg {} }

@media(max-width: theme('screens.sm-max')) {
  .searchbar {
    max-width: 35rem; }

  .searchbar__content {
    opacity: 0;
    visibility: hidden;
    transition: opacity $transition-default, visibility 0s linear $transition-duration-default;
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: $white-smooth;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    overflow: auto;
    padding: 5rem;
    form {
      overflow: auto; } }
  .searchbar__content.active {
    opacity: 1;
    visibility: visible;
    transition: opacity $transition-default; }
  .searchbar__content {
    i.fas {
      color: $gray; } } }
