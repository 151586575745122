////////////////////
// PRETTY DEFAULT
////////////////////////////////////////////////////////////////////////////////
.pretty {
  $current: &;
  @apply whitespace-normal {}
  @apply leading-12 {}
  .state {
    label {
      font-weight: 600;
      font-size: 1.5rem;
      @apply pl-10 {}
      text-indent: 0;
      &::before,
      &::after {
        top: 0; } } }
  .state.p-white {
      &:before {
        border: .2rem solid $white !important;
        border-radius: .3rem !important; } }

  .state {
    label {
      &:before {
        border: .2rem solid $primary !important;
        border-radius: .3rem !important; }
      &:after {
        transform: scale(0.9) !important; } } } }
.pretty.p-default input:checked ~ .state label:after {
  content: '\f00c';
  color: $white;
  background-color: $primary !important;
  font-family: var(--font-family-icon); }

.pretty.p-default input:checked ~ .state.p-white label:after {
  content: '\f00c';
  color: $white;
  background-color: $primary !important;
  font-family: var(--font-family-icon); }

.reservation-checkbox.pretty .state label {
  @apply text-lg #{!important} {}
  padding-left: 3.5rem; }

.pretty-rgpd {
  .state label {
    @apply font-normal {}
    @apply opacity-75 {} } }

.pretty-legal-conditions {
  .pretty {
    @apply mr-2 {}

    .state label {
      @apply font-medium {}
      @apply opacity-75 {} } } }
