////////////////////
// HEADING
////////////////////////////////////////////////////////////////////////////////

// BASE
// ---------------------------------------------------------
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-bottom: 1.5rem;
  font-weight: bold; }


.title--xl {
  @include font-size(16rem);
  line-height: 13vh;

  @screen lg {
    @include font-size(17rem);
    line-height: 20vh; } }

.title--lg {
  @include font-size(7.5rem); }


h1, .h1 {
  @include font-size(6rem); }

.title--md {
  @include font-size(4.5rem); }

h2, .h2 {
  @include font-size(3.5rem); }


h3, .h3 {
  @include font-size(2.5rem); }


h4, .h4 {
  @include font-size(2rem); }


h5, .h5 {
  @include font-size(1.8rem); }


h6, .h6 {
  font-size: 1.6rem; }

@media (max-width: theme('screens.md-max')) {
  h5, .h5 {
    font-size: 1.8rem; }
  h5, .h5 {
    font-size: 2rem; } }



////////////////////
// BODY TEXT
////////////////////////////////////////////////////////////////////////////////
.text-loud {
  font-size: 200%; }

.text-title {
  font-family: 'Barcelony', sans-serif; }

small {
  font-size: 80%; }
