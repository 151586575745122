// PROJECT - Component COOKIES - Studio HB 2017

////////////////////
// COOKIES DEFAULT
////////////////////////////////////////////////////////////////////////////////

// COOKIE WRAPPER
// ---------------------------------------------------------
$cookies-action-width: 12rem;
.cookies-alert {
  animation-duration: 1s;
  animation-name: slidein;
  animation-fill-mode: forwards;
  background: $gray-dark;
  border-radius: 0;
  bottom: 100%;
  color: rgba($white, .5);
  display: block;
  font-size: 1.4rem;
  left: 0;
  margin-bottom: 0;
  position: fixed;
  padding: 1.5rem 0rem;
  text-align: left;
  width: 100%;
  @apply z-cookie {}

  p {
    margin-bottom: 0rem;
    font-weight: normal;
    text-align: justify;

    a {
      color: rgba($white, .5);
      &:hover {
        color: rgba($white, 1); } } } }


.cookies-text {
  width: calc(100% - #{$cookies-action-width});
  padding-bottom: 1rem;
  padding-right: 3rem; }
.cookies-action {
  width: $cookies-action-width; }

@media (max-width: theme('screens.sm-max')) {
  .cookies-text {
    width: 100%; } }

///// COOKIE ANIMATION
@keyframes slidein {
  from {
    bottom: -100%; }

  to {
    bottom: 0; } }
