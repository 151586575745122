// PROJECT - Component PAGINATION - Studio HB 2017

////////////////////
// PAGINATION DEFAULT
////////////////////////////////////////////////////////////////////////////////

$pagination-width: 3.2rem;
$pagination-height: 3.2rem;

$pagination-bg: $white;
$pagination-bg-active: $white;

$pagination-color: $gray;
$pagination-color-active: $gray-dark;

$pagination-border: transparent;
$pagination-border-active: $gray-dark;

$pagination-font-size: 1.4rem;



// DEFAULT STYLE
// ---------------------------------------------------------
ul.pagination {
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 14.2rem;
  padding-inline-start: 0;
  .page-link, {
    border-radius: 0%;
    display: block;
    font-size: 1.8rem;
    font-weight: 500;
    height: 3.5rem;
    line-height: 1.9;
    text-align: center;
    width: 3.5rem;
    &:hover {
      background-color: $primary;
      color: $secondary; } }
  li {
    display: inline-block;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    text-align: center;
    vertical-align: middle;

    &.active {
      .page-link {
        background-color: $secondary;
        color: white;
        font-weight: 700;
        &:hover {
          background-color: $secondary;
          color: white; } } } }

  li:first-child,
  li:last-child, {
    .page-link {
      font-size: 0px;
      line-height: $pagination-height - 0.2rem;
      overflow: hidden;
      padding: 0;
      text-align: center;
      &::before {
        content: "\f053";
        display: block;
        font-family: var(--font-family-icon); // TODO : AJOUT FLECHE DROITE
        font-size: 1.7rem;
        font-weight: 300; }
      &:hover {
        background-color: $white-smooth;
        color: $primary;
        &::before {
          color: $primary; } } } }

  li:last-child {
    .page-link::before {
      transform: rotate(180deg); } }

  li:first-child {
    .page-link {
      display: inline-block;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      overflow: hidden;
      text-align: center; } }


  li:last-child {
    .page-link {
      font-size: 0px;
      line-height: 3.3rem;
      overflow: hidden;
      padding: 0;
      text-align: center; } } }
