// PROJECT - Layout BASE - Studio HB 2017

////////////////////
// LAYOUT
////////////////////////////////////////////////////////////////////////////////

// LAYOUT BASE
// ---------------------------------------------------------
body, html {
  width: 100%; }


html {
  font-size: 62.5%; } // on passe la taille de base à 10px pour les unités en rem : 10px = 1rem (1,4rem = 14px etc)


body {
  background-color: $white-smooth;
  color: $primary;
  font-size: $text-font-size;
  font-family: var(--font-family-sans);

  //// pb scintillement font-weight
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }


input {
  font-size: $text-font-size;
  font-family: var(--font-family-sans); }

img, svg {
  @apply inline {} }

// LAYOUT WRAPPER
// ---------------------------------------------------------
.main-wrapper {
  min-height: calc(100vh - #{$header-height}); }


.container {
  @apply mx-auto {}
  padding-left: $container-padding-x;
  padding-right: $container-padding-x; }





////////////////////
// LINKS
////////////////////////////////////////////////////////////////////////////////
a:hover, a:focus {
  text-decoration: none;
  outline: none; }






////////////////////
// HR
////////////////////////////////////////////////////////////////////////////////
hr {
  @apply h-px {}
  @apply bg-secondary {}
  @apply opacity-30 {}
  @apply my-6 {} }
