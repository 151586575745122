// PROJECT - Component INSTAGRAM FEED - Studio HB 2019

////////////////////
// INSTAGRAM FEED DEFAULT
////////////////////////////////////////////////////////////////////////////////
.instagram-feed {
  img {
    width: 100%; }
  .instagram-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(23%, 1fr));
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    article {
      @apply flex {}
      @apply items-center {} } } }

@media (max-width: theme('screens.sm-max')) {
  .instagram-feed {
    .instagram-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
      grid-column-gap: 0.4rem;
      grid-row-gap: 0rem; } } }
