////////////////////
// FOOTER
////////////////////////////////////////////////////////////////////////////////
.main-footer {
  min-height: 47rem;
  @apply text-secondary {} }



////////////////////
// SIGNATURE SHB
////////////////////////////////////////////////////////////////////////////////
.signature-shb {
  display: inline-block;
  font-size: 0;
  height: 20px;
  position: relative;
  vertical-align: top;
  text-transform: none;
  width: 26px;
  margin-left: 1rem;
  // before + after
  &::before,
  &::after {
    background-image: url("../../frontend/images/footer-signature.svg");
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity 250ms linear;
    width: 26px; }
  // after
  &::after {
    background-position: 0px 0px;
    opacity: 1; }
  // before
  &::before {
    background-position: -26px 0px;
    opacity: 0; }
  // hover before + after
  &:hover {
    &::after {
      opacity: 0; }
    &::before {
      opacity: 1; } } }

@media (max-width: theme('screens.md-max')) {
  ///// SIGNATURE SHB
  .signature-shb-wrapper {
    display: block;
    margin-top: 0.5rem; }

  .signature-shb {
    font-size: $text-font-size;
    width: auto;
    font-size: 1.2rem;

    span {
      display: inline-block;
      padding-left: 35px; //-> let place to logo
      text-transform: uppercase; } } }
