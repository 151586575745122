////////////////////
// SASS VARIABLES
////////////////////////////////////////////////////////////////////////////////

// COLORS
// ---------------------------------------------------------
$primary: #171c44;
$secondary: #bba640;

$brand-pink: #e782aa;
$brand-green: #18332f;
$brand-cyan: #54bbb5;
$brand-orange: #ff9b75;

$gray-xlight: #f2f2f2;
$gray-light: #c7c7c7;
$gray: #979797;
$gray-dark: #2a2e36;

$white-smooth: #fffef9;
$white: #ffffff;
$black: #000000;

/////// USEFULL COLORS
$cyan: #62c5da;
$green: #36d2a1;
$red: #ff6363;
$yellow: #ffaf57;

///// SHARED COLORS
$brand-info: $cyan;
$brand-success: $green;
$brand-danger: $red;
$brand-warning: $yellow;


// LAYOUT
// ---------------------------------------------------------
$header-height: 8.5rem;
$header-mobile-height: 7.7rem;
$footer-min-height: 6rem;
$container-padding-x: theme('spacing.6');


// TRANSITIONS
// ---------------------------------------------------------
$transition-duration-default: 350ms;
$transition-timing-default: cubic-bezier(.39,0,.4,1);
$transition-default: $transition-duration-default $transition-timing-default;
$transition-duration-smooth: 800ms;
$transition-timing-smooth: cubic-bezier(.53,0,.25,.79);
$transition-smooth: var(--transition-duration-smooth) var(--transition-timing-smooth);


// TYPOGRAPHY
// ---------------------------------------------------------
$text-font-size: 1.5rem; //-> general font-size apply on body


// COMPONENTS
// ---------------------------------------------------------
$input-height: 5rem;
$thumb-blog-img-width: 370px; //-> blog thumb img width, in px





////////////////////
// CSS CUSTOM PROPERTIES
////////////////////////////////////////////////////////////////////////////////

// https://sass-lang.com/documentation/breaking-changes/css-vars
 :root {
  ///// COLORS
  --primary: #{$primary};
  --secondary: #{$secondary};
  --brand-pink: #{$brand-pink};
  --brand-green: #{$brand-green};
  --brand-cyan: #{$brand-cyan};
  --brand-orange: #{$brand-orange};

  --gray-100: #{$gray-xlight};
  --gray-400: #{$gray-light};
  --gray-600: #{$gray};
  --gray-800: #{$gray-dark};
  --black: #{$black};
  --white: #{$white};
  --white-smooth: #{$white-smooth};
  --blue: #{$cyan};
  --green: #{$green};
  --red: #{$red};
  --yellow: #{$yellow};

  ///// FONTS
  --font-family-sans: 'SF Pro Display', "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-family-title: 'Barcelony', var(--font-family-sans);
  --font-family-icon: 'Font Awesome 5 Pro';
  --font-family-icon-brands: 'Font Awesome 5 Brands';

  ///// LAYOUT
  --header-height: #{$header-height};
  --footer-min-height: #{$footer-min-height};
  --container-padding-x: theme('spacing.6');

  ///// TYPOGRAPHY
  --text-font-size: #{$text-font-size}; //-> general font-size apply on body

  ///// COMPONENTS
  --input-height: #{$input-height};

  ///// ANIMATIONS
  --transition-duration-default: #{$transition-duration-default};
  --transition-timing-default: #{$transition-timing-default};
  --transition-default: #{$transition-duration-default} #{$transition-timing-default};
  --transition-duration-smooth:  #{$transition-duration-smooth};
  --transition-timing-smooth:  #{$transition-timing-smooth};
  --transition-smooth:  #{$transition-duration-smooth}  #{$transition-timing-smooth}; }





////////////////////
// OVERRIDE VENDORS
////////////////////////////////////////////////////////////////////////////////
$rfs-rem-value: 10; // for responsive-font-size: 1rem = 10px
$rfs-factor: 4;
$rfs-breakpoint: 1600px;
$rfs-base-font-size: 1.4rem;
$enable-rfs: true;
$lg-path-fonts: '~lightgallery.js/src/fonts';
$lg-path-images: '~lightgallery.js/src/img';
