// PROJECT - Component WYZIWYG - Studio HB 2017

////////////////////
// WYSIWYG DEFAULT
////////////////////////////////////////////////////////////////////////////////
.wysiwyg-wrapper {
  @apply leading-20 {}
  @apply text-lg {}
  @apply text-justify {}



  // HEADING
  // ---------------------------------------------------------
  h1,
  h2,
  h3,
  h4 {
    @apply leading-13 {}
    font-weight: 700;
    @apply mb-8 {}
    @apply text-center {} }

  h1 {
    @apply text-3xl {} }

  h2 {
    @apply text-2xl {} }

  h3 {
    @apply text-xl {} }

  h4 {
    @apply text-lg {} }



  // CONTENT
  // ---------------------------------------------------------
  p {
    @apply text-lg {}
    @apply mb-8 {} }



  // IMAGE
  // ---------------------------------------------------------
  img {
    height: auto;
    max-width: 100%; } }
