// PROJECT - Shared RESPONSIVE-SHARED - Studio HB 2017

////////////////////
// CUSTOM XS
////////////////////////////////////////////////////////////////////////////////
@media (max-width: 440px) {

  ///// FLASH MESSAGE
  .flash-message {
    left: 0;
    right: 0;
    width: 100%;

    &.show {
      .alert {
        transform: translateY(0%);
        transition: transform $transition-default; } }

    .alert {
      box-shadow: inherit;
      border-radius: 0;
      text-align: center;
      transition: transform $transition-default;
      transform: translateY(-100%); } } }
