// PROJECT - Page BASIC - Studio HB 2017

////////////////////
// BASIC
////////////////////////////////////////////////////////////////////////////////
.basic-page {
  padding-bottom: 10rem;

  h1 {
    margin-top: 4rem;
    margin-bottom: 2rem; }


  ///// BASIC CARD
  .basic-card {
    h1 {
      margin-top: 0; }

    h2 {
      margin-top: 4.5rem; }

    h3,
    h4,
    h5,
    h6 {
      margin-top: 3rem; } } }
