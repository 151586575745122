////////////////////
// ADDRESS ITEM
////////////////////////////////////////////////////////////////////////////////
.address-item {
  @apply text-center {}

  &__image {
    @apply max-w-full {}
    @apply flex {}
    @apply items-end {}
    @apply justify-center {}

    img {
      max-width: 80%;
      @apply max-h-full {}
      @apply h-auto {}
      @apply w-auto {}
      @apply w-full {} } }


  &__title {
    @include margin-top(-5rem);
    @include margin-bottom(2rem);
    @extend .title--lg;
    @apply font-title {} }

  &__subtitle {
    @extend .title--md; }


  @screen lg {
    &__image {
      height: 25rem; } } }


//// in a slider
.agile__slide {
  .address-item {
    .btn {
      @apply opacity-0 {}
      @appy transition-smooth {} } }

  &--active {
    .address-item {
      .btn {
        @apply opacity-100 {} } } } }


.address-item--small {
  .address-item__image {
    img {
      max-height: 25rem;
      @apply w-full {} } } }
