////////////////////
// PARALLAX IMAGE
////////////////////////////////////////////////////////////////////////////////
.parallax-image__scroll {
  .parallax-image {
    &:nth-child(1) {
      margin-top: -30rem; }
    &:nth-child(2) {
      margin-top: 10vw; } }
  + .parallax-image__scroll {
    .parallax-image {
      &:nth-child(1) {
        margin-top: -0vw; }
      &:nth-child(2) {
        margin-top: 16.5vw; } }
    + .parallax-image__scroll {
      .parallax-image {
        &:nth-child(1) {
          -margin-top: -10vw; }
        &:nth-child(3) {
          margin-top: 0vw; } } } } }




.parallax-image {
  &__wrapper {
    position: relative; }

  &__title {
    @apply fixed {}
    top: 50%;
    left: 0;
    right: 0;
    @apply -translate-y-1/2 {}
    @apply opacity-0 {}
    @apply invisible {}
    transition: opacity $transition-default, visibility 0s linear $transition-duration-default;
    z-index: 30;

    &.opacity-100 {
      transition: opacity $transition-smooth; }

    a {
      transition: none; } } }


@media (max-width: theme('screens.md-max')) {
  .parallax-image-scroll__image {
    @apply h-100 {} }
  .parallax-image__scroll {
    .parallax-image {
      transform: translate3d(0,0,0) !important;
      &:nth-child(1) {
        margin-top: -10rem; }
      &:nth-child(2) {
        margin-bottom: 5.5rem; } } }
  .parallax-image__scroll {
    + .parallax-image__scroll {
      + .parallax-image__scroll {
        .parallax-image {
          &:nth-child(2) {
            margin-top: 70vw; } } } }

    &--xl {
      .parallax-image:nth-child(1) {
        padding-bottom: 35vw; } } } }



@media (max-width: theme('screens.xs-max')) {
  .parallax-image__scroll {
    .parallax-image {
      transform: translate3d(0,0,0) !important;
      &:nth-child(1) {
        margin-top: -10rem; }
      &:nth-child(2) {
        margin-bottom: 5.5rem; } } }
  .parallax-image__scroll {
    .parallax-image:nth-child(1) {
      margin-bottom: 80vw; }

    &--xl {
      .parallax-image:nth-child(1) {
        padding-bottom: 80vw; } } } }
