////////////////////
// LEAFLET
////////////////////////////////////////////////////////////////////////////////
.leaflet-container {
  z-index: 0 !important; }

.leaflet-top,
.leaflet-bottom {
  z-index: 22 !important; }

.leaflet-pane {
  z-index: 20 !important; }
