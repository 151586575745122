// PROJECT - Component LINK - Studio HB 2017

////////////////////
// LINKS
////////////////////////////////////////////////////////////////////////////////

// LINK DEFAULT
// ---------------------------------------------------------
a {

  color: $primary;
  transition: all $transition-default;
  text-decoration: none;
  &:hover,
  &:focus {
    color: darken($primary, 5%); } }



// LINK COLOR
// ---------------------------------------------------------

///// LINK GRAYDARK PRIMARY
.link-graydark-primary {
  color: $gray-dark;
  &:hover,
  &:focus {
    color: $primary; } }

///// LINK PRIMARY SECONDARY
.link-primary-secondary {
  color: $primary;
  &:hover,
  &:focus {
    color: $secondary; } }

///// LINK SECONDARY WHITE
.link-white-secondary {
  text-decoration: none;
  color: $white;
  &:hover,
  &:focus {
    color: $secondary; } }

///// LINK GRAY PRIMARY
.link-gray-primary {
  color: $gray;
  &:hover,
  &:focus {
    color: $primary; } }


///// LINK PRIMARY
.link-primary {
  text-decoration: none;
  color: $primary;
  &:hover,
  &:focus {
    color: $primary; } }

///// LINK SECONDARY
.link-secondary {
  text-decoration: none;
  color: $secondary;
  &:hover,
  &:focus {
    color: $secondary; } }

///// LINK WHITE
.link-white {
  color: $white;
  &:hover,
  &:focus {
    color: $white; } }



// LINK SHAPE
// ---------------------------------------------------------
.link-underline {
  display: inline-block;
  position: relative;
  &::before {
    background-color: $secondary;
    content: '';
    display: block;
    height: 40%;
    opacity: .5;
    transition: width $transition-default;
    width: 50%;
    position: absolute;
    bottom: 5%;
    z-index: -1; }
  &:hover,
  &:focus,
  &.active {
    &::before {
      width: 100%; } }

  &--center-mobile {
    &::before {
      transform: translateX(-25%);
      left: 25%;
      @screen lg {
        @apply transform {}
        @apply translate-x-0 {}
        left: 0; } } } }



// LINK SHAPE CENTER
// ---------------------------------------------------------
@media(max-width : theme('screens.md-max')) {
  .link-underline-center::before {
    left: 40%;
    transform: translate(-50%); } }


// LINK SHAPE CONCEPT
// ---------------------------------------------------------
.link-underline-fill {
  display: inline-block;
  position: relative;
  &::before {
    background-color: $secondary;
    content: '';
    display: block;
    height: 10%;
    opacity: 1;
    transition: width $transition-default;
    width: 0%;
    position: absolute;
    bottom: -5%;
    z-index: -1; }
  &:hover,
  &:focus {
    &::before {
      width: 100%; } } }


.link-underline-fill {
  &::before {
    background-color: $white; } }
.header--scroll {
  .link-white {
    color: $secondary; }
  .link-underline-fill {
    &::before {
      background-color: $secondary; } } }



// LINK ICON
// ---------------------------------------------------------

///// LINK ICON
.link-icon {
  #{class-begin('fa-')} {
    font-size: 1.3em;
    width: 1em; }

  span {
    display: inline-block;
    vertical-align: middle;
    + span {
      margin-left: 0.5rem; } }

  .text {
    line-height: 1.1;
    max-width: calc(100% - 1.3em); }

  span + .text {
    max-width: calc(100% - 1.3em - 0.5rem); } }


///// LINK ICON PRIMARY
.link-icon--primary {
  color: $gray-light;
  &:hover,
  &:focus,
  &.active {
    color: $gray-dark;
    #{class-begin('fa-')} {
      color: $primary; } }

  #{class-begin('fa-')} {
    color: $gray-light;
    transition: color $transition-default; }

  span {
    text-transform: uppercase; } }



// LINK FACEBOOK
// ---------------------------------------------------------
.link-facebook {
  color: $gray;
  display: block;
  letter-spacing: 0.02rem;
  margin-bottom: 2rem;
  text-align: center;

  #{class-begin('fa-')} {
    color: #3b5998; // Facebook color blue
    font-size: 2.5rem;
    width: 2.5rem; }

  .text {
    max-width: calc(100% - 2.5rem); } }



// LINK SCROLL TOP
// ---------------------------------------------------------
.link-scroll-top {
  align-items: center;
  background-color: $primary;
  bottom: 2rem;
  border-radius: 0;
  cursor: pointer;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  opacity: 0;
  position: fixed;
  right: 2rem;
  transition: opacity $transition-default 0s, visibility $transition-default $transition-duration-default;
  visibility: hidden;
  width: 3.5rem;
  z-index: 25;

  &.visible {
    opacity: 1;
    transition: opacity $transition-default 0s;
    visibility: visible; }

  span {
    color: $white;
    display: inline-block;
    font-size: 1.6rem;
    vertical-align: middle; } }
