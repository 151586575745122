.header_white {
  .loader {
    display: none; } }
.loader {
  display: block;
  z-index: 95;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  overflow: hidden;
  animation: loader-display 100ms linear 1350ms both;

  &__pink,
  &__green,
  &__blue,
  &__gold {
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    position: absolute; }

  &__pink {
    background-color: $brand-pink;
    z-index: 4;
    animation: loader-color 250ms $transition-timing-default 0s both; }

  &__green {
    background-color: $brand-green;
    z-index: 3;
    animation: loader-color 250ms $transition-timing-default 250ms both; }

  &__gold {
    background-color: $primary;
    z-index: 2;
    animation: loader-color 250ms $transition-timing-default 500ms both; }

  &__blue {
    background-color: $secondary;
    z-index: 1;
    // animation: loader-color 250ms $transition-timing-default 500ms both
    animation: loader-swipe 600ms $transition-timing-default 750ms both; }

  .trigger-loader & {
    display: block; } }


@keyframes loader-color {
  0%,
  99% {
    opacity: 1; }

  100% {
    opacity: 0; } }

@keyframes loader-swipe {
  0%,
  50%, {
    bottom: 0%; }
  100% {
    bottom: 100%; } }



@keyframes loader-display {
  0%,
  99% {
    z-index: 99; }
  100% {
    z-index: -1; } }
