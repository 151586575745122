////////////////////
// COMMON STYLE DATE PICKER
////////////////////////////////////////////////////////////////////////////////
.common-datepicker {
  @apply font-sans {}
  @apply relative {}

  @screen md {
    @apply z-10 {} }

  .datepicker--open {
    @apply z-datepicker {} }

  .datepicker {
    .square {
      @apply cursor-default {}
      border-right: 2px solid transparent; }

    &__tooltip {
      @apply ml-0 {}
      margin-left: -10px;
      margin-top: 0;
      width: 65px;
      font-size: 8px;
      @apply transform {}
      @apply -translate-y-full {}
      @apply text-center {}
      pointer-events: none; }

    &__months::before {
      @apply hidden #{!important} {} }

    &__month-name {
      @screen md {
        padding-bottom: 30px; } }

    &__month-day {
      @apply relative {}
      @apply text-base {}
      @apply font-semibold {}
      @apply font-sans {}
      margin: 0 !important;
      @apply outline-none #{!important} {}
      @apply border-none #{!important} {}

      .day-number {
        @apply relative {}
        z-index: 20;
        font-size: 13px; }

      .day-price {
        @apply relative {}
        z-index: 20;
        font-size: 11px; }

      &--selected {
        border-width: 0px !important; }

      &--valid {
        @apply cursor-pointer #{!important} {} } }

    &__month-day--hidden {
      @apply opacity-0 #{!important} {}
      @apply pointer-events-none #{!important} {} }

    &__month-day--disabled {
      @apply opacity-100 {} }

    &__month-day--first-day-selected,
    &__month-day--last-day-selected,
    &__month-day--selected {
      @apply opacity-100 {} }

    &__month-day,
    &__month-day--first-day-selected,
    &__month-day--check-in-forbidden {
      &::after {
        content: '';
        @apply absolute {}
        @apply top-0 {}
        @apply left-0 {}
        @apply right-0 {}
        @apply bottom-0 {}
        @apply w-full {}
        @apply h-full {}
        @apply bg-contain {}
        @apply bg-no-repeat {}
        @apply bg-right-bottom {}
        z-index: 10; } }

    &__month-day--last-day-selected,
    &__month-day--check-out-forbidden {
       &::before {
        content: '';
        @apply absolute {}
        @apply top-0 {}
        @apply left-0 {}
        @apply right-0 {}
        @apply bottom-0 {}
        @apply w-full {}
        @apply h-full {}
        @apply bg-contain {}
        @apply bg-no-repeat {}
        @apply bg-left-top {}
        z-index: 10; } }

    &__month-name {
      font-weight: 700;
      text-transform: uppercase;
      @apply text-lg {}
      @apply font-sans {} }

    &__week-name {
      font-weight: 700;
      text-transform: uppercase;
      @apply text-base {}
      @apply font-sans {} }

    // Shadow on mobile
    &__week-row {
      @apply shadow-none {} }

    // Big arrow month
    &__month-button {
      @apply relative {}
      @apply border-transparent {}
      @apply bg-none {}
      height: 50px;

      &::after {
        content: '\f061';
        @apply block {}
        @apply font-icon {}
        @apply text-2xl {}
        @apply absolute {}
        top: 10px; } }

    &__wrapper {
      @apply relative {}
      @apply bg-none {} } // Remove original calendar icon

    // Month separator
    &__months::before {
      @apply bg-transparent {} }

    &__month-button:focus {
      @apply outline-none #{!important} {} }

    &__inner {
      @apply static {} }

    &__input {
      @apply outline-none #{!important} {}
      @apply shadow-none {}
      @apply appearance-none {} }

    button.next--mobile {
      @apply bg-red {}
      @apply outline-none {}
      z-index: 30;

      &:focus {
        @apply outline-none {} }

      &::after {
        background: transparent;
        content: '\f078';
        @apply absolute {}
        top: 50%;
        @apply left-0 {}
        @apply right-0 {}
        @apply mx-auto {}
        @apply block {}
        @apply font-icon {}
        @apply text-lg {}
        width: 40px;
        height: 40px;
        transform: translateY(-20%); } } } }

////////////////////
// COMPACT MODE DATEPICKER + MODAL MOBILE
////////////////////////////////////////////////////////////////////////////////
.compact-datepicker {
  .datepicker {
    @apply left-auto {}
    @apply right-0 {}

    @screen md {
      top: 58px; }

    @screen md {
      top: 56px; }

    &__tooltip--mobile {
      @apply hidden {} }

    &__input {
      @apply relative {}
      @apply text-lg {}
      @apply font-sans {}
      padding: 0;
      @apply text-center {}
      font-weight: 500;
      @apply text-base {}

      @screen md {
        @apply border-0 {}
        @apply h-full {}
        @apply text-lg {}
        line-height: 47px; }

      &:first-child {
        @apply bg-none {} } // Remove original arrow icon

      &:last-child {
        padding-left: 10px;
        padding-right: 25px; } }

    &__dummy-wrapper {
      height: 3.43em; }

    &__wrapper {
      // Compact view - Calandar
      &::after {
        content: '\f274';
        @apply absolute {}
        top: 11px;
        left: 15px;
        @apply block {}
        @apply font-icon {}
        @apply text-lg {} }

      // Compact view - Arrow
      &::before {
        content: '\f105';
        @apply absolute {}
        top: 8px;
        left: 0;
        right: 0;
        @apply mx-auto {}
        @apply block {}
        @apply font-icon {}
        @apply text-xl {}
        @apply text-center {}
        font-weight: 200; } }

    &__close-button {
      top: 5px;
      right: 5px;
      @apply left-auto {}
      width: 25px;
      font-size: 0;
      @apply transform-none {}

      &::before {
        content: '\f00d';
        @apply absolute {}
        @apply top-0 {}
        @apply left-0 {}
        @apply right-0 {}
        @apply mx-auto {}
        @apply block {}
        @apply font-icon {}
        font-size: 25px;
        @apply text-center {}
        font-weight: 300; } }

    &__clear-button {
      top: 4px;
      width: 30px; } }

  .datepicker--open {
    .datepicker {
      &__input {
        @apply border-2 {}
        @apply border-solid {}
        @apply border-primary {}
        width: calc(50% - 10px);
        text-indent: 0;
        padding: 0;
        height: 5rem;
        line-height: 5rem;
        @apply text-lg {} }

      &__dummy-wrapper {
        padding: 25px 10px 10px 10px;
        height: 9.5rem;
        @apply flex {}
        @apply justify-between {}

        &::before {
          content: '\f105';
          @apply absolute {}
          top: 60px;
          left: 0;
          right: 0;
          @apply mx-auto {}
          @apply block {}
          @apply font-icon {}
          @apply text-xl {}
          @apply text-center {}
          @apply font-normal {}

          @screen md {
            @apply hidden {} } } }

      &__months {
        @media (max-width: 767px) {
          margin-top: 145px;
          height: calc(var(--app-height) - 145px); } }

      &__week-row {
        margin-top: 55px;
        z-index: 12;

        @screen md {
          margin-top: 0; } } } } }

.price-datepicker {
  .datepicker {
    &__month-day {
      @apply relative {} }

    &__month-day .day-number {
      @apply absolute {}
      @apply left-0 {}
      @apply right-0 {}
      @apply mx-auto {}
      top: 10%; }

    &__month-day .day-price {
      @apply absolute {}
      @apply left-0 {}
      @apply right-0 {}
      @apply mx-auto {}
      font-weight: 400;
      bottom: 10%; } } }

.datepicker-tablet-center {
  .datepicker--open {
    @screen md {
      left: -150px;
      right: initial; }

    @screen lg {
      left: initial;
      right: 0; } } }

@screen md {
  .always-visible {
    .datepicker {
      width: 700px;
      @apply mx-auto {}
      max-height: initial;
      @apply static #{!important} {}

      &__dummy-wrapper {
        @apply hidden {} }

      &__wrapper {
        &::after,
        &::before {
          @apply hidden {} } }

      &__clear-button {
        @apply hidden {} }

      &__week-row {
        @apply border-transparent {} } } } }
