

// ANIM SOLO
// ---------------------------------------------------------
.anim-fade,
.anim-fade-30 {
  opacity: 0;
  transition-property: opacity;
  transition-duration: $transition-duration-smooth;
  transition-timing-function: $transition-timing-smooth; }

.anim-up,
.anim-down {
  transition-property: transform;
  transition-duration: $transition-duration-smooth;
  transition-timing-function: $transition-timing-smooth; }

.anim-up {
  transform: translate3d(0, 70%, 0); }

.anim-down {
  transform: translate3d(0, -70%, 0); }


// ANIM MIXED
// ---------------------------------------------------------
.anim-fade-up,
.anim-fade-down {
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: $transition-duration-smooth;
  transition-timing-function: $transition-timing-smooth; }

.anim-fade-up {
  transform: translate3d(0, 70%, 0); }

.anim-fade-down {
  transform: translate3d(0, -70%, 0); }





////////////////////
// ANIMATION IN
////////////////////////////////////////////////////////////////////////////////
.in {

  &.anim-fade,
  .anim-fade {
    opacity: 1; }

  &.anim-fade-30,
  .anim-fade-30 {
    @apply opacity-30 {} }

  &.anim-up,
  .anim-up,
  &.anim-down,
  .anim-down {
    transform: translate3d(0, 0%, 0); }

  &.anim-fade-up,
  .anim-fade-up,
  &.anim-fade-down,
  .anim-fade-down, {
    opacity: 1;
    transform: translate3d(0, 0%, 0); } }

// ANIM DELAY
// ---------------------------------------------------------
.delay-sm {
  transition-delay: 180ms; }

.delay-md {
  transition-delay: 360ms; }

.delay-lg {
  transition-delay: 540ms; }




// CASCAD
// ---------------------------------------------------------
.anim--cascad {
  .anim-up,
  .anim-down,
  .anim-fade-up,
  .anim-fade-down,
  .anim-fade {
    @include transitionChildDelayUp(0ms, 200ms, 1, 100); } }




@include transitionDelayUp(0ms, $transition-duration-smooth * 0.5, 1, 100);


.bg-anim-launcher {
  transition: color 1s ease 0.2s;

  @screen xl {
    &:hover {
      color: $white;
      transition: color .5s ease 0s;
      .color-stroke {
        stroke: $white; }
      .color-fill {
        fill: $white; }
      .fill-background {
        transform: matrix(1, 0, 0, 1, 0, 0) translate(-50%,-50%); } } } }

.fill-background {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -50;
  transform: matrix(0.001, 0, 0, 0.001, 0, 0) translate(-50%,-50%);
  transition: transform .9s ease;
  svg {
    transform: translate(-50%,-50%);
    position: absolute;
    top: 50%;
    left: 50%; } }
