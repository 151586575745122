////////////////////
// Dropdown
////////////////////////////////////////////////////////////////////////////////

.vue-dropdown {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 1.3rem;

  font-size: 1.8rem;
  font-weight: 500;
  &__menu {
    &__group {
      width: 100%;
      font-size: 1.8rem;
      font-weight: 500;
      padding-top: 1.75rem;
      padding-bottom: 1.75rem;
      &:first-child {
        padding-top: 3.4rem; } } }
  .minus-circle,
  .plus-circle {
    cursor: pointer;
    padding: 0.95rem;
    i {
      font-size: 2.5rem; } }
  .number {
    span {}
    padding: 0.95rem; }

  .vue-dropdown__btn {
    width: 100%;
    padding: 0;
    margin-top: 0;
    &::before {
      content: '\f0dd';
      font-weight: bold;
      font-family: var(--font-family-icon);
      top: -0.25rem;
      text-align: center;
      position: absolute;
      transition: all $transition-default;
      right: 0rem;
      width: 3rem; } } }
.reversearrow {
  .vue-dropdown__btn::before {
    transform: rotate(180deg); } }


////////////////////
// Person Counter Dropdown
////////////////////////////////////////////////////////////////////////////////
.person-counter__dropdown {
  .vue-dropdown {
    border-color: $primary;
    .minus-circle,
    .plus-circle {
      color: $secondary; }
    .vue-dropdown__btn {
      &::before {
        color: $primary; } } } }


////////////////////
// Person Counter Dropdown
////////////////////////////////////////////////////////////////////////////////
.price-picker__dropdown {
  .vue-dropdown {
    border-color: $primary;
    .vue-dropdown__btn {
      &::before {
        color: $primary; } } } }

////////////////////
// Additional Services  Dropdown
////////////////////////////////////////////////////////////////////////////////
.additional-services__dropdown {
  .vue-dropdown {
    border-color: $primary;
    color: $primary;
    padding: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border: 0;
    border-top: .2rem solid $primary;
    border-bottom: .2rem solid $primary;
    .vue-dropdown__btn {
      &::before {
        position: static; } } }
  .reversearrow {
    .vue-dropdown__btn::before {
      transform: rotate(180deg); } } }
