.pick-modal {
  @apply fixed {}
  @apply h-full {}
  @apply w-full {}
  background-color: $primary;
  @apply z-modal {}
  @apply top-0 {}
  @apply left-0 {}
  @apply transform {}
  @apply translate-y-full-r {}
  transition: transform $transition-default; }
.pick-logo {
  height: 2.6rem;
  width: 17rem;
  svg {
    fill: $white; } }
.pick-container {
  @apply max-w-md {} }
.pick-modal.active {
  @apply transform {}
  @apply translate-y-0 {}
  .fas {
    color: grey; } }

.modal-exit--grey {
  position: absolute;
  top: 2.8rem;
  right: 2.8rem;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 50%;
  background-color: rgba($black,0.44);
  #{class-begin('fa')} {
    color: white;
    line-height: 1.75;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%; } }

@media(max-width: theme('screens.md-max')) {
  .modal-exit--grey {
    transform: translate(-50%,-50%);
    position: absolute;
    top: 5.0rem;
    left: 50%;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%; } }
