// PROJECT - Component MODAL - Studio HB 2017

////////////////////
// MODAL DEFAULT
////////////////////////////////////////////////////////////////////////////////

$default-modal-width: 60rem;




// MODAL WRAPPER
// ---------------------------------------------------------
.modal {
  text-align: center;
  padding: 0;
  &::before { // Le before sert à aligner la modal verticalement
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle; } }



// MODAL DIALOG
// ---------------------------------------------------------
.modal-dialog {
  display: inline-block;
  max-width: $default-modal-width;
  text-align: left;
  vertical-align: middle; }



// MODAL CONTENT
// ---------------------------------------------------------
.modal-content {
  border: none;
  border-radius: 0;
  padding: 2rem; }



// MODAL HEADER
// ---------------------------------------------------------
.modal-header {
  border-bottom: none;
  padding: 3rem 1rem 1rem;

  .close {
    font-size: 2em;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    transition: all $transition-default; } }



// MODAL BODY
// ---------------------------------------------------------
.modal-body {
  font-size: 1.5rem;
  line-height: 1.9;
  padding: 1rem 3rem 3rem 1rem; }



// MODAL FOOTER
// ---------------------------------------------------------
.modal-footer {
  border-top: none;
  padding: 1rem 0 0 1rem; }

// MODAL EXIT
// ---------------------------------------------------------
.modal-exit {
  position: absolute;
  top: 3.6rem;
  right: 3.6rem;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 50%;
  background-color: #242424;
  @apply cursor-pointer {}

  .fal {
    color: $secondary;
    line-height: 1.75;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%; } }


@media (max-width: theme('screens.md-max')) {
  .modal-exit {
    top: 1.6rem;
    right: 1.6rem;
    width: 3.5rem;
    height: 3.5rem; } }
// MODAL BACKDROP
// ---------------------------------------------------------
.modal-backdrop.show {
  opacity: 0.7; }
