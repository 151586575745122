// PROJECT - Helpers FRONT-MIXINS - Studio HB 2015

// UNDERLINE
// Usage : @include underline(color)
// Argument : couleur du trait. Par défaut : noir
// Retourne un trait de la couleur sélectionné sous l'élément
@mixin underline($color: black, $margin: 0 auto, $border-height: 4px) {
  content: "";
  display: block;
  margin: $margin;
  height: 15px;
  width: 65px;
  border-bottom: $border-height solid $color; }





//Usage : @include stripes
// Arguments: couleur 1, couleur 2, background-size
// Fond avec bandes inclinées
@mixin stripes($color1: rgba(255,255,255,.65), $color2: transparent, $background-size: 40px) {
  background-image: linear-gradient(45deg,$color1 25%, $color2 25%, $color2 50%,$color1 50%,$color1 75%, $color2 75%, $color2);
  background-image: -webkit-linear-gradient(45deg,$color1 25%, $color2 25%, $color2 50%,$color1 50%,$color1 75%, $color2 75%, $color2);
  background-size: $background-size $background-size; }






//Usage :
// @include placeholder // or +placeholder
//    css rule 1
//    css rule 2
//    ...
// Crée automatiquement les placeholders avec les préfixes navigateurs
@mixin placeholder() {
  &::-webkit-input-placeholder {
    @content; }

  &:-moz-placeholder {
    @content; }

  &::-moz-placeholder {
    @content; }

  &:-ms-input-placeholder {
    @content; } }






// Usage :  class-begin(cssBeginClass).
//          #{class-begin(cssBeginClass)} si on veut écrire des caractéristiques dedans
// Arguments: cssBeginClass: le début de la classe css que l'on veut cibler
// Retourne [class^="cssBeginClass"], [class*=" cssBeginClass"]
// (utile par exemple pour cibler les colonnes bootstrap quelque soit leur taille, ou les icones générés par une font icon)
// cf http://stackoverflow.com/questions/9083614/sass-multiple-class-selector-from-a-variable
@function class-begin($value) {
  $list: '[class^="#{$value}"]', '[class*=" #{$value}"]';
  $glue: ', ';
  $string: "";
  @each $item in $list {
    @if $string != "" {
      $string: $string + $glue; }
    $string: $string + $item; }
  @return $string; }





//Usage : +transitionDelayUp(280ms, 1, 10)
// Arguments: $stepDuration: le délai que l'on veut ajouter
//            $firstStep, $lastStep : les valeurs min/max de la boucle
// Crée des delais de transitions en cascade
@mixin transitionChildDelayUp($defaultduration, $stepDuration, $firstStep, $lastStep) {

  $currentDuration: $defaultduration;

  @for $i from $firstStep through $lastStep {
    &:nth-child(#{$i}) {
      transition-delay: $currentDuration; }

    $currentDuration: $currentDuration + $stepDuration; } }


@mixin transitionDelayUp($defaultduration, $stepDuration, $firstStep, $lastStep) {

  $currentDuration: $defaultduration;

  @for $i from $firstStep through $lastStep {
    .anim-delay-#{$i} {
      transition-delay: $currentDuration; }

    $currentDuration: $currentDuration + $stepDuration; } }



