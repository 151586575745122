.slider-thumb {
  &.swiper-container-slider {
    @apply relative {}
    height: 250px #{!important};

    @screen lg {
      height: 475px #{!important}; } } }

//Inner slide thumb
.thumb-inner-slide {
  &.disable-prev-option {
    .swiper-button-prev {
      @apply opacity-0 #{!important} {}
      @apply invisible #{!important} {} } }

  .swiper-button-prev {
    @apply h-16 #{!important} {}
    @apply w-16 #{!important} {}
    @apply rounded-full {}
    background-color: rgba(255, 255, 255, 0.6);
    @apply transition {}
    @apply transition-colors {}
    @apply ease-in {}
    @apply duration-300 {}

    &.swiper-button-disabled {
      @apply opacity-0 #{!important} {}
      @apply invisible #{!important} {} }

    &::hover {
      background-color: rgba(255, 255, 255, 1);
      &::after {
        @apply text-primary {} } }

    &::after {
      content: '\f053';
      @apply absolute {}
      top: 12px;
      left: 15px;
      @apply text-base {}
      @apply text-center {}
      @apply text-secondary {}
      @apply font-icon {}
      @apply flex {}
      @apply items-center {}
      @apply justify-center {}
      font-weight: 700;
      @apply bg-transparent {}
      @apply transition {}
      @apply transition-colors {}
      @apply ease-in {}
      @apply duration-300 {} } }


  .swiper-button-next {
    @apply h-16 #{!important} {}
    @apply w-16 #{!important} {}
    @apply rounded-full {}
    background-color: rgba(255, 255, 255, 0.6);
    @apply transition {}
    @apply transition-colors {}
    @apply ease-in {}
    @apply duration-300 {}

    &::hover {
      background-color: rgba(255, 255, 255, 1);
      &::after {
        @apply text-primary {} } }

    &::after {
      content: '\f054';
      @apply absolute {}
      top: 12px;
      right: 15px;
      @apply text-base {}
      @apply text-center {}
      @apply text-secondary {}
      @apply font-icon {}
      font-weight: 700;
      @apply transition {}
      @apply bg-transparent {}
      @apply transition {}
      @apply transition-colors {}
      @apply ease-in {}
      @apply duration-300 {} } } }
