// HEADER SCROLL
// ---------------------------------------------------------
.header_secondary {

  .main-header {

    .header__logo {
      svg {
        fill: $secondary; } }

    .navbar-nav {
      .burger-menu {
        .icon-group {
          .line1,
          .line2,
          .line3 {
            background-color: $secondary; } } } }

    .link-white {
      color: $secondary; } //Override var for brand-pink banner

    .nav-item {
      a {
        color: $primary; }

      &::after {
        border-color: $primary; } }

    .nav-item.active a {
      color: $secondary; }

    .nav-separator {
      &::after {
        border-color: $secondary; } } } }
