.booked {
  pointer-events: none;
  opacity: 0.5; }

.box {
  position: relative;
  box-shadow: 0 0 15px rgba(0,0,0,.1); }

.ribbon {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0; }

.ribbon span {
  position: absolute;
  display: block;
  width: 190px;
  padding: 1px 0;
  background-color: #171c44;
  color: #FFF;
  font-weight: 700;
  font-size: 1.2rem;
  --font-family-sans: 'SF Pro Display', "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-family-title: 'Barcelony', var(--font-family-sans);
  --font-family-icon: 'Font Awesome 5 Pro';
  --font-family-icon-brands: 'Font Awesome 5 Brands';
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  right: -25px;
  top: 40px;
  transform: rotate(-45deg);
  z-index: 1;

  .ribbon::before,
  .ribbon::after {}
  border-top-color: transparent;
  border-left-color: transparent; }

.ribbon::before {
  top: 0;
  right: 0; }

.ribbon::after {
  bottom: 0;
  left: 0; }
