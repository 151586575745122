// PROJECT - Helpers FRONT-HELPERS - Studio HB 2017

////////////////////
// BASICS CLASSES
////////////////////////////////////////////////////////////////////////////////

///// TEXT TRANSFORM
.uppercase {
  text-transform: uppercase; }


///// FONT WEIGHT
.font-bold {
  font-weight: 700; }

.font-sbold {
  font-weight: 500; }

.font-light {
  font-weight: 300; }


///// FONT STYLE
.italic {
  font-style: italic; }


///// TEXT DECORATION
.underline {
  text-decoration: underline; }


.border-box {
  box-sizing: border-box; }


////////////////////
// BOOTSTRAP GRID
////////////////////////////////////////////////////////////////////////////////
.row.xs-gutter {
  margin-left: -0.2rem;
  margin-right: -0.2rem; }

[class^='col'],
[class*=' col'] {
  &.xs-gutter {
    padding-left: 0.2rem;
    padding-right: 0.2rem; } }

.row.sm-gutter {
  margin-left: -0.5rem;
  margin-right: -0.5rem; }

[class^='col'],
[class*=' col'] {
  &.sm-gutter {
    padding-left: 0.5rem;
    padding-right: 0.5rem; } }


.row.md-gutter {
  margin-left: -0.7rem;
  margin-right: -0.7rem; }

[class^='col'],
[class*=' col'] {
  &.md-gutter {
    padding-left: 0.7rem;
    padding-right: 0.7rem; } }

.row.no-gutter {
  margin-left: 0.0rem;
  margin-right: 0.0rem; }

[class^='col'],
[class*=' col'] {
  &.no-gutter {
    padding-left: 0.0rem;
    padding-right: 0.0rem; } }

.row.lg-gutter {
  margin-left: -2.5rem;
  margin-right: -2.5rem; }

[class^='col'],
[class*=' col'] {
  &.lg-gutter {
    padding-left: 2.5rem;
    padding-right: 2.5rem; } }

@media(max-width : theme('screens.sm-max')) {
  .row.lg-gutter {
    margin-left: -1rem;
    margin-right: -1rem; }

  [class^='col'],
  [class*=' col'] {
    &.lg-gutter {
      padding-left: 1rem;
      padding-right: 1rem; } } }


////////////////////
// GENERATE SPECIAL CLASSES
////////////////////////////////////////////////////////////////////////////////

// Usage : @include createMargin($min, $max)
// $min : taille min de la marge (en px), $max : taille max de la marge (en px)
// Crée des classes .m-X, .mt-X, .mr-X, .mb-X, .ml-X pour générer des marges automatiquement (/! taille en px)
@mixin createMargin($min, $max) {
  @for $i from $min to $max+1 {
    .m-#{$i} {
      margin : 0.1rem * $i; }

    .m-t-#{$i} {
      margin-top: 0.1rem * $i; }

    .m-l-#{$i} {
      margin-left : 0.1rem * $i; }

    .m-r-#{$i} {
      margin-right: 0.1rem * $i; }

    .m-b-#{$i} {
      margin-bottom: 0.1rem * $i; }

    .m-i-#{$i} {
      margin : 0.1rem * $i !important; }

    .m-t-i-#{$i} {
      margin-top: 0.1rem * $i !important; }

    .m-l-i-#{$i} {
      margin-left : 0.1rem * $i !important; }

    .m-r-i-#{$i} {
      margin-right: 0.1rem * $i !important; }

    .m-b-i-#{$i} {
      margin-bottom: 0.1rem * $i !important; } } }

@include createMargin(0, 100);

// Usage : @include createPadding($min, $max)
// $min : taille min du padding (en px), $max : taille max du padding (en px)
// Crée des classes .p-X, .pt-X, .pr-X, .pb-X, .pl-X pour générer des marges automatiquement (/! taille en px)
@mixin createPadding($min, $max) {
  @for $i from $min to $max+1 {
    .p-#{$i} {
      padding : 0.1rem * $i; }

    .p-t-#{$i} {
      padding-top: 0.1rem * $i; }

    .p-l-#{$i} {
      padding-left : 0.1rem * $i; }

    .p-r-#{$i} {
      padding-right: 0.1rem * $i; }

    .p-b-#{$i} {
      padding-bottom: 0.1rem * $i; }

    .p-i-#{$i} {
      padding : 0.1rem * $i !important; }

    .p-t-i-#{$i} {
      padding-top: 0.1rem * $i !important; }

    .p-l-i-#{$i} {
      padding-left : 0.1rem * $i !important; }

    .p-r-i-#{$i} {
      padding-right: 0.1rem* $i !important; }

    .p-b-i-#{$i} {
      padding-bottom: 0.1rem * $i !important; } } }

@include createPadding(0, 100);
