@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SF-pro-display-light.eot');
  src: url('../fonts/SF-pro-display-light.eot') format('embedded-opentype'), url('../fonts/SF-pro-display-light.woff2') format('woff2'), url('../fonts/SF-pro-display-light.woff') format('woff'), url('../fonts/SF-pro-display-light.ttf') format('truetype'), url('../fonts/SF-pro-display-light.svg#SF-pro-display-light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SF-pro-display-regular.eot');
  src: url('../fonts/SF-pro-display-regular.eot') format('embedded-opentype'), url('../fonts/SF-pro-display-regular.woff2') format('woff2'), url('../fonts/SF-pro-display-regular.woff') format('woff'), url('../fonts/SF-pro-display-regular.ttf') format('truetype'), url('../fonts/SF-pro-display-regular.svg#SF-pro-display-regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SF-pro-display-medium.eot');
  src: url('../fonts/SF-pro-display-medium.eot') format('embedded-opentype'), url('../fonts/SF-pro-display-medium.woff2') format('woff2'), url('../fonts/SF-pro-display-medium.woff') format('woff'), url('../fonts/SF-pro-display-medium.ttf') format('truetype'), url('../fonts/SF-pro-display-medium.svg#SF-pro-display-medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SF-pro-display-semibold.eot');
  src: url('../fonts/SF-pro-display-semibold.eot') format('embedded-opentype'), url('../fonts/SF-pro-display-semibold.woff2') format('woff2'), url('../fonts/SF-pro-display-semibold.woff') format('woff'), url('../fonts/SF-pro-display-semibold.ttf') format('truetype'), url('../fonts/SF-pro-display-semibold.svg#SF-pro-display-semibold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SF-pro-display-bold.eot');
  src: url('../fonts/SF-pro-display-bold.eot') format('embedded-opentype'), url('../fonts/SF-pro-display-bold.woff2') format('woff2'), url('../fonts/SF-pro-display-bold.woff') format('woff'), url('../fonts/SF-pro-display-bold.ttf') format('truetype'), url('../fonts/SF-pro-display-bold.svg#SF-pro-display-bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SanFranciscoDisplay-Heavy.eot');
  src: url('../fonts/SanFranciscoDisplay-Heavy.eot') format('embedded-opentype'), url('../fonts/SanFranciscoDisplay-Heavy.woff2') format('woff2'), url('../fonts/SanFranciscoDisplay-Heavy.woff') format('woff'), url('../fonts/SanFranciscoDisplay-Heavy.ttf') format('truetype'), url('../fonts/SanFranciscoDisplay-Heavy.svg#SF-pro-display-semibold') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SanFranciscoDisplay-Black.eot');
  src: url('../fonts/SanFranciscoDisplay-Black.eot') format('embedded-opentype'), url('../fonts/SanFranciscoDisplay-Black.woff2') format('woff2'), url('../fonts/SanFranciscoDisplay-Black.woff') format('woff'), url('../fonts/SanFranciscoDisplay-Black.ttf') format('truetype'), url('../fonts/SanFranciscoDisplay-Black.svg#SF-pro-display-semibold') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap; }


@font-face {
  font-family: 'Barcelony';
  src: url('../fonts/Barcelony.eot');
  src: url('../fonts/Barcelony.eot') format('embedded-opentype'), url('../fonts/Barcelony.woff2') format('woff2'), url('../fonts/Barcelony.woff') format('woff'), url('../fonts/Barcelony.ttf') format('truetype'), url('../fonts/Barcelony.svg#Barcelony') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap; }
