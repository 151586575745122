// PROJECT - Component THUMB - Studio HB 2017

////////////////////
// THUMB BORDERED
////////////////////////////////////////////////////////////////////////////////

// THUMB WRAPPER
// ---------------------------------------------------------
.thumb-bordered {
  @apply text-center {}

  &__image {
    @apply relative {}
    font-size: 0; }

  &__title {
    @apply transition {}

    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @apply overflow-hidden {}
      text-overflow: ellipsis; } }

  &__slider {
    @apply relative {} } }

