.spacing-container {
  margin-top: 25rem; }


@media (max-width: theme('screens.md-max')) {
  .spacing-container {
    margin-top: 32rem; } }


@media (max-width: theme('screens.sm-max')) {
  .spacing-container {
    margin-top: 10.5rem; } }
