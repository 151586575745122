// PROJECT - Shared FLASH - Studio HB 2017

////////////////////
// FLASH MESSAGE
////////////////////////////////////////////////////////////////////////////////
.flash-message {
  opacity: 0;
  position: fixed;
  right: 20px;
  transition: opacity $transition-default 0s, visibility $transition-default $transition-duration-default;
  top: 20px;
  visibility: hidden;
  z-index: 90;

  &.show {
    opacity: 1;
    transition: opacity $transition-default 0s;
    visibility: visible; }

  ///// ALERT
  .alert {
    box-shadow: 0 0 5px rgba(0,0,0,0.25);
    background: #fff;
    border: 0;
    border-radius: 2px;
    font-size: 15px;
    font-weight: normal;
    max-width: 440px;
    padding: 15px 25px 15px 15px;
    text-align: left; }

  /// Alert danger
  .alert-danger {
    background: $brand-danger;
    color: #fff; }

  /// Alert info
  .alert-info {
    background: $brand-info;
    color: #fff; }

  /// Alert success
  .alert-success {
    background: $brand-success;
    color: #fff; }

  /// Alert wraning
  .alert-warning {
    background: $brand-warning;
    color: #fff; } }


///// CLOSE
.flash-close {
  color: #fff;
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  right: 3px;
  top: 0px;
  opacity: 0; }

.flash-message:hover .flash-close {
  opacity: 1; }
