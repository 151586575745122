// PROJECT - Layout HEADER - Studio HB 2017

////////////////////
// HEADER
////////////////////////////////////////////////////////////////////////////////

// HEADER DEFAULT
// ---------------------------------------------------------


@keyframes logo-swipe {
  0% {
    top: 50%; }
  100% {

    top: 0%; } }




@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.main-header {
  font-family: var(--font-family-sans);
  height: $header-height;
  transition: all 0.3s ease;
  width: 100%;
  @apply z-header {}
  margin: 0;
  padding: 0;
  line-height: normal;
  .button--back {
    display: none; }
  .trigger-loader & {
    .burger-menu,
    .language-choice {
      animation: fade 850ms $transition-timing-smooth 1000ms both; }
    .header__logo {
      animation: logo-swipe 850ms $transition-timing-smooth 700ms both;
      svg {
        z-index: 120; } } } }


.navbar-nav {
  width: 100%;


  .burger-menu {
    width: 50%;
    @apply z-header-button {}
    .link_icon-group.active {
      opacity: 0;
      transition: all 0.5s $transition-smooth; }
    .icon-group {
      .line1, .line2, .line3 {
        width: 3.4rem;
        height: 0.2rem;
        background-color: $white;
        margin: 0.8rem 0;
        transition: 0.4s;
        transform-origin: 90% 0%; }
      .line1.active {
        transform: rotate(-45deg) translate(-0.1rem, 0);
        height: 0.2rem; }

      .line2.active {
        opacity: 0; }

      .line3.active {
        transform: rotate(45deg);
        height: 0.2rem; } } }


  .language-choice {
    width: 50%; } }

.nav-item {
  a {
    color: $white; }

  &.active {
    a {
      color: $secondary; } }

  &:first-child {
    padding-right: 0;

    &::after {
      content: '';
      border-right: .2rem solid $white;
      height: 50%;
      left: 0;
      top: 25%;
      margin-left: 1rem; } } }

.header__logo {
  height: 2.6rem;
  width: 17rem;
  z-index: 8000;
  position : fixed;
  transform : translate(-50%,0%);
  left: 50%;
  top: 0%;
  padding-top: 2.75rem;
  svg {
    fill: $white;
    width: 100%;
    height: auto; } }

@media (max-width: theme('screens.sm-max')) {
  .main-header {
    height: $header-mobile-height; }
  .header__logo {
    height: 1.8rem;
    width: 11.9rem;
    padding-top: 2.9rem; }

  .navbar-nav {
    .burger-menu {
      .icon-group {
        .line1, .line2, .line3 {
          width: 3.4rem;
          height: 0.3rem; } } } } }


////////////////////
// FULLSCREEN MENU
////////////////////////////////////////////////////////////////////////////////

.fullscreen-menu {
  position: fixed;
  transform: translate(-50%,-50%);
  top: -100%;
  left: 50%;
  width: 150%;
  height: 100%;
  animation: bg-color-change 7s ease-in-out infinite both;
  @apply z-header-fullscreen {}
  transition: all 1s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  .central-menu {
    font-family: var(--font-family-sans);
    position: absolute;
    color: $white-smooth;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
    overflow: auto;
    height: calc(100% - #{$header-height} - 12rem);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title-transition {
      transition: all 350ms cubic-bezier(.39, 0, .4, 1) !important; }
    li {
      line-height: 1.35; } }
  .social-media {
    position: absolute;
    transform: translate(-50%,-50%);
    bottom: 2rem;
    left: 50%; }
  .locations-discover {
    max-height: 0;
    overflow: hidden;
    // transition: 0.4s ease-in
    transition: all 1s cubic-bezier(0.165, 0.840, 0.440, 1.000); }
  .locations-discover.active {
    max-height: 16.5rem; } }

.fullscreen-menu.active {
  top: 50%; }

@media (max-width: theme('screens.sm-max')) {
  .central-menu {
    top: 50%; } }


// HEADER SCROLL
// ---------------------------------------------------------
.header--scroll {
  background-color: $white-smooth;
  position: fixed;
  transition: transform $transition-default;
  transform: translateY(0%);

  .navbar-toggler,
  .navbar-brand {
    transform: translateY(0rem);
    animation-delay: 0ms; } }


.header--minimized {
  position: fixed;
  transform: translateY(-100%); }

.submenu-open {
  .header--scroll {
    background-color: transparent;
    position: fixed;
    transition: none;
    transform: none;

    .navbar-toggler,
    .navbar-brand {
      transform: none;
      animation-delay: 0ms; } }


  .header--minimized {
    position: fixed;
    transform: none; } }


.header--scroll {
  .header__logo {
    svg {
      fill: $secondary; } }
  .navbar-nav {
    .burger-menu {
      .icon-group {
        .line1,
        .line2,
        .line3 {
          background-color: $secondary; } } } }

  .link-white {
    color: $secondary; }

  .language-choice {
    .nav-item {
      a {
        @apply text-primary {} }

      &:after {
        @apply border-primary {} } }

    .nav-item.active {
      a {
        @apply text-secondary {} } } }

  .nav-separator {
    &::after {
      border-color: $secondary; } } }


@media (max-width: theme('screens.md-max')) {
  .header_white {
    .main-header {
      .burger-menu {
        display: none; }
      .button--back {
        display: block;
        height: 4.5rem;
        width: 4.5rem; } } } }
