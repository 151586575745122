////////////////////
// BANNER
////////////////////////////////////////////////////////////////////////////////
.banner {
  position: relative;
  min-height: 40rem;
  animation: bg-color-change 7s ease-in-out infinite both;
  width: 100%;
  @include padding-top(7rem);
  @include padding-bottom(7rem); }
.banner--xl {
  @include padding-top(14rem);
  @include padding-bottom(0rem); }

.banner-sm {
  @extend .banner;
  min-height: 8.5rem;
  padding: 0; }
@keyframes bg-color-change {
  0% {
    background-color: $primary; }
  50% {
    background-color: $brand-green; }
  100% {
    background-color: $primary; } }
.banner-white {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: $white-smooth;
  width: 100%;
  height: 6.8rem; }

.banner--secondary {
  animation: none;
  background-color: $secondary; }

.banner--dark-secondary {
  animation: none;
  background-color: darken($secondary, 10%); }

.banner--pink {
  position: relative;
  min-height: 40rem;
  background-color: $brand-pink; //TODO : VOIR SI ON DEFINI LA COULEUR DANS LE CSS (COULEUR GENERIQUE) OU DANS LE HTML POUR FACILITER SON CHANGEMENT
  width: 100%;
  @include padding-top(7rem);
  @include padding-bottom(7rem);
  svg {
    .color-stroke {
      stroke: $white; }
    .color-fill {
      fill: $white; } }
  .button--back {
    color: $white;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: rgba($white, 0.3);
    #{class-begin('fa')} {
      position: relative;
      transform: translate(-50%,-50%);
      top: 50%;
      left: 50%; } } }
.banner__title {
  transform: translate(-50%,-50%);
  top: 60%;
  left: 50%; }

.banner--pink {
  position: relative;
  min-height: 40rem;
  background-color: $brand-pink;
  width: 100%;
  @include padding-top(7rem);
  @include padding-bottom(7rem);
  svg {
    .color-stroke {
      stroke: $white; }
    .color-fill {
      fill: $white; } } }

.button--back {
  color: $white;
  width: 5rem;
  height: 5rem;
  opacity: 0.7;
  position: relative;
  border-radius: 50%;
  background-color: rgba($white, 0.3);
  &::before {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%; }
  &:hover {
    opacity: 1; } }

@media(max-width : theme('screens.md-max')) {
  .banner--pink {
    .button--back {
      display: none; } } }

@media(max-width : theme('screens.sm-max')) {
  .banner {
    min-height: 25rem;
    .banner__title {
      width: 80%; } }
  .banner-sm {
    min-height: 8.5rem; } }
