// PROJECT - Page CONTACT - Studio HB 2017

////////////////////
// CONTACT
////////////////////////////////////////////////////////////////////////////////
.contact-page {
  padding-bottom: 0.1rem; } // avoid margin bug on child


.contact__card-body {
  padding-bottom: 6rem; }



// CONTACT INFOS
// ---------------------------------------------------------
.contact__infos {
  margin-top: 1.4rem;

  .card-body {
    padding: 7rem 2rem 12.5rem 2rem; } }



// CONTACT ADDRESS
// ---------------------------------------------------------
.contact__address {
  font-size: 1.9rem;
  margin-bottom: 1.5rem; }



// CONTACT ICON
// ---------------------------------------------------------
.contact__icon {
  color: $primary;
  font-size: 3.2rem;
  margin-bottom: 2rem; }



// CONTACT PHONE
// ---------------------------------------------------------
.contact__phone {
  @extend .link-graydark-primary;
  border-top: 0.1rem solid rgba($gray-light, .5);
  border-bottom: 0.1rem solid rgba($gray-light, .5);
  display: block;
  font-weight: 700;
  font-size: 2.5rem;
  margin-top: 1.5rem;
  margin-bottom: 2.3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
