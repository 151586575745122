// PROJECT - Component BUTTON - Studio HB 2017

////////////////////
// BUTTONS
////////////////////////////////////////////////////////////////////////////////

// BUTTON DEFAULT
// ---------------------------------------------------------

///// BUTTON
.btn {
  cursor: pointer;
  font-size: 2rem;
  @apply font-semibold {}
  @apply py-5 {}
  @apply px-8 {}
  position: relative;
  overflow: hidden;
  display: inline-block;
  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 50%;
    right: 100%;
    margin: -1.5rem 0 0 0.1rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    transform-origin: 0% 50%;
    transform: scale3d(1, 2, 1);
    transition: all $transition-default; }

  &:hover {
    &::before {
      transform: scale3d(15, 15, 1);
      opacity: 1; } } }


.btn:focus,
button:focus {
  box-shadow: none;
  outline: 0; }

.btn:hover,
button:hover {
  cursor: pointer; }


// BUTTON WRAPPER
// ---------------------------------------------------------

.btn-wrapper {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  .btn {
    margin: 0.5rem; } }


// BUTTON COLOR
// ---------------------------------------------------------

///// BUTTON PRIMARY
.btn-primary {
  background-color: $primary;
  border-color: $primary;
  color: $white;
  &:hover {
    background-color: $primary;
    border-color: $primary; }
  &:focus,
  &:active,
  &.active,
  &:disabled {
    background-color: $primary;
    border-color: $primary;
    color: $white; }
  &::before {
    background-color: darken($primary, 5%); } }

///// BUTTON SECONDARY
.btn-secondary {
  color: $white;
  background: $secondary;
  vertical-align: middle;
  z-index: 1;
  &:hover {
    color: $white; }
  &:disabled {
    background-color: $gray-light;
    border-color: $gray-light;
    color: $gray; }
  &::before {
    background: darken($secondary, 5%); } }


///// BUTTON GRAY
.btn-gray {
  background-color: $gray;
  border-color: $gray;
  color: $white;
  &:hover {
    background-color: $gray-light;
    border-color: $gray-light;
    color: $white; }
  &:focus,
  &:active,
  &.active {
    background-color: $gray;
    border-color: $gray;
    color: $white; }
  &::before {
    background-color: rgba($gray-dark, .5); } }




///// BUTTON OUTLINE PRIMARY
.form-btn-outline-primary {
  @apply bg-transparent {}
  color: $primary;
  border: 0.2rem solid $primary;
  padding: 1.3rem;
  width: 100%; }


///// BUTTON OUTLINE SECONDARY

.btn-outline-secondary {
  @apply bg-transparent {}
  color: $secondary;
  border: 0.2rem solid $secondary;
  transition: border 0.5s, color 0.5s;
  &::before {}
  &:hover {
    color: $white;
    border: 0.2rem solid $white; }
  &.btn-lg {
    border-width: 0.3rem; } }

.btn-outline-secondary-whitebg {
  @apply bg-transparent {}
  color: $secondary;
  border: 0.2rem solid $secondary;
  &:hover {
    @apply bg-transparent {}
    color: darken($secondary, 10%);
    border-color: darken($secondary, 10%); }

  &.btn-lg {
    border-width: 0.3rem; } }


///// BUTTON OUTLINE CYAN
.btn-outline-cyan {
  @apply bg-transparent {}
  color: $brand-cyan;
  border: 0.2rem solid $brand-cyan;
  transition: border 0.5s, color 0.5s;
  &::before {}
  &:hover {
    color: $white;
    border: 0.2rem solid $white; }
  &.btn-lg {
    border-width: 0.3rem; } }


///// BUTTON OUTLINE GREEN
.btn-outline-green {
  @apply bg-transparent {}
  color: $brand-green;
  border: 0.2rem solid $brand-green;
  transition: border 0.5s, color 0.5s;
  &::before {}
  &:hover {
    color: $white;
    border: 0.2rem solid $white; }
  &.btn-lg {
    border-width: 0.3rem; } }


///// BUTTON OUTLINE PINK
.btn-outline-pink {
  @apply bg-transparent {}
  color: $brand-pink;
  border: 0.2rem solid $brand-pink;
  transition: border 0.5s, color 0.5s;
  &::before {}
  &:hover {
    color: $white;
    border: 0.2rem solid $white; }
  &.btn-lg {
    border-width: 0.3rem; } }


///// BUTTON OUTLINE ORANGE
.btn-outline-orange {
  @apply bg-transparent {}
  color: $brand-orange;
  border: 0.2rem solid $brand-orange;
  transition: border 0.5s, color 0.5s;
  &::before {}
  &:hover {
    color: $white;
    border: 0.2rem solid $white; }
  &.btn-lg {
    border-width: 0.3rem; } }


///// BUTTON OUTLINE WHITE PRIMARY
.btn-outline-white-primary {
  @apply bg-transparent {}
  color: $white;
  border: 0.2rem solid $white;
  transition: border 0.5s, color 0.5s; }
.btn-outline-white-primary:hover {
  color: $primary;
  border: 0.2rem solid $primary; }
.btn-outline-white-primary.btn-lg {
  border-width: 0.3rem; }

///// BUTTON OUTLINE WHITE PRIMARY
.btn-outline-white-secondary {
  @apply bg-transparent {}
  color: $white;
  border: 0.2rem solid $white;
  transition: border 0.5s, color 0.5s; }
.btn-outline-white-secondary:hover {
  color: $secondary;
  border: 0.2rem solid $secondary; }
.btn-outline-white-secondary.btn-lg {
  border-width: 0.3rem; }


///// BUTTON SHADOW
.btn-shadow {
  background-color: $white;
  box-shadow: 0rem 0.2rem 0.7rem 0rem rgba($black, .2);
  color: $gray;
  transition: none;
  &:hover {
    color: $gray; }
  &:focus,
  &.focus,
  &:focus {
    box-shadow: 0rem 0.2rem 0.7rem 0rem rgba($black, .2); } }



// BUTTON SIZE
// ---------------------------------------------------------

///// BUTTON LARGE
.btn-lg {
  padding-right: 5.4rem;
  padding-left: 5.4rem;
  font-size: 2.5rem;
  padding-top: 2.0rem;
  padding-bottom: 2.0rem; }



///// BUTTON SMALL
.btn-sm {
  font-size: 1.4rem;
  padding: 0.4rem 1.1rem; }



// BUTTON ICON
// ---------------------------------------------------------

///// BUTTON ICON
.btn-icon {
  span {
    display: inline-block;
    vertical-align: middle; }
  // #class-begin('fa-')
  //font-size: 1.4em
  //margin-top: -0.5em
  //margin-bottom: -0.5em
  //&:last-child
  //  margin-left: 1rem
  //&:first-child
 }  //  margin-right: 1rem



// BUTTON SHAPE
// ---------------------------------------------------------

///// BUTTON CIRCLE
.btn-circle {
  border-radius: 50%;
  font-size: 2.4rem;
  height: 3.6rem;
  line-height: 3.6rem;
  padding: 0;
  width: 3.6rem; }




// BOOK BUTTON POSITION
// ---------------------------------------------------------

.bottom-button {
  position: fixed;
  bottom: 0;
  right: 0; }
