.v--modal-overlay {
  padding-top: 4%;
  padding-bottom: 4%; }

// GUIDE MODAL
// ---------------------------------------------------------
@media (max-width: theme('screens.md-max')) {
  .places-list {
    .v--modal-overlay {
      padding: 0; }
    .v--modal-box {
      top: 0 !important;
      height: 100vh !important; } } }
