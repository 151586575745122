// PROJECT - Component VIDEO - Studio HB 2017
////////////////////
// VIDEO DEFAULT
////////////////////////////////////////////////////////////////////////////////
.video-wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;

  iframe {
    max-width: 100%; } }

////////////////////
// HOME VIDEO
////////////////////////////////////////////////////////////////////////////////
@keyframes player-swipe {
  0% {
    top: calc(#{$header-height} + 5rem + 15rem); }
  100% {
    top: calc(#{$header-height} + 5rem); } }
@keyframes player-opacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
.player {
  background-color: $brand-green; }
.video-container {
  animation: player-swipe 1s $transition-timing-smooth 0ms both;
  .player {
    animation: player-opacity 1s $transition-timing-smooth 0ms both; }
  .title-video {
    animation: player-opacity 1s $transition-timing-smooth 0ms both; }

  .trigger-loader & {
    animation: player-swipe 1s $transition-timing-smooth 1.1s both; } }


.video-container {
  margin: 0;
  .player {
    overflow: hidden;
    height: 70vh;
    margin: 0 auto;
    position: relative;

    iframe {
      height: 200%;
      width: 150%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); } } }

@media (max-width: theme('screens.lg-max')) {
  .video-container {
    .player {
      width: 100%;
      height: 50vh; } } }

@media (max-width: theme('screens.sm-max')) {
  @keyframes player-swipe-mobile {
    0% {
      top: calc(5rem + 25rem);
      opacity: 0; }
    100% {
      top: 15rem;
      opacity: 1; } }
  .video-container {
    animation: player-swipe-mobile 1s $transition-timing-smooth 0ms both;

    .trigger-loader & {
      animation: player-swipe-mobile 1s $transition-timing-smooth 1.1s both; } }

  .video-container {
    margin: 0;

    .player {
      overflow: hidden;
      height: 60vh;
      max-width: none;
      position: relative;

      iframe {
        height: 140%;
        width: 350%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } } } }



////////////////////
// TITLE VIDEO
////////////////////////////////////////////////////////////////////////////////

@keyframes title-swipe {
  0% {
    top: 70%; }
  100% {
    top: 53%; } }
.video-container {
  .title-video {
    color: $white;
    font-weight: 700;
    z-index: 5;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    margin: 0 auto;
    width: 100%; }
  .title-video {
    animation: title-swipe 1s $transition-timing-smooth 0ms both;
    .trigger-loader & {
      animation: title-swipe 1s $transition-timing-smooth 1.1s both; } } }



@media (max-width: theme('screens.sm-max')) {
  @keyframes title-swipe-mobile {
    0% {
      top: 65%; }
    100% {
      top: 53%; } }

  .video-container {
    .title-video {
      animation: title-swipe-mobile 1s $transition-timing-smooth 0ms both;
      .trigger-loader & {
        animation: title-swipe-mobile 1s $transition-timing-smooth 1.1s both; } } } }
