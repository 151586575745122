////////////////////
// VUE AGILE
////////////////////////////////////////////////////////////////////////////////
$current: &;

.agile {
  &__actions {
    @apply mt-6 {}
    @apply relative {} }


  &__nav-button {
    font-size: 0;
    background-color: transparent;
    @apply border-none {}
    @apply cursor-pointer {}
    @apply transition {}
    &::before {
      content: '\f177';
      @apply text-xl {}
      @apply text-gray-400 {}
      @apply font-icon {}
      @apply flex {}
      @apply items-center {}
      @apply justify-center {}
      @apply h-16 {}
      @apply w-16 {}
      font-weight: 300;
      @apply transition {} }
    &--next {
      &::before {
        content: '\f178'; } }

    &:hover {
      &::before {
        @apply text-gray-800 {} } } }


  &__dots {
    transform: translate3d(-50%, -50%, 0);
    left: 50%;
    top: 50%;
    @apply absolute {} }

  &__dot {
    @apply mx-4 {}

    button {
      font-size: 0;
      line-height: 0;
      @apply bg-white {}
      @apply border-none {}
      border-radius: 50%;
      @apply cursor-pointer {}
      @apply block {}
      @apply h-4 {}
      margin: 0;
      padding: 0;
      @apply transition {}
      @apply w-4 {}
      @apply opacity-50 {}

      .agile--secondary & {
        @apply bg-secondary {} } }

    &--current,
    &:hover {
      button {
        @apply opacity-100 {} } } } }



.agile-slider-thumb {
  &:hover {
    .agile__actions {
      @screen lg {
        @apply opacity-100 {}
        @apply visible {} } } }

  .agile__actions {
    @apply absolute {}
    top: 50%;
    @apply w-full {}
    @apply mt-0 {}
    transform: translateY(-50%);
    transition-duration: $transition-duration-smooth;

    @screen lg {
      @apply opacity-0 {}
      @apply invisible {} } }

  .agile__nav-button {
    @apply absolute {}
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.8);
    @apply rounded-full {}
    z-index: 3;
    &::before {
      @apply text-lg {}
      height: 4rem;
      width: 4rem;
      @apply text-secondary {}
      font-weight: 700; } }

  .agile__nav-button--next {
    right: 1.0rem;
    &::before {
      content: '\f054'; } }

  .agile__nav-button--prev {
    left: 1.0rem;
    &::before {
      content: '\f053'; } } }


.agile-detail-slider {
  .agile__actions {
    width: 100vw; } }

////////////////////
// SLIDER CUT
////////////////////////////////////////////////////////////////////////////////
.slider-cut {
  @include padding-top(5rem);
  @include padding-bottom(5rem); }


@screen md {
  .slider-cut {
    margin-left: -10%;
    margin-right: -10%;
    &__wrapper {
      @apply overflow-x-hidden {} } } }




////////////////////
// SLIDER SET
////////////////////////////////////////////////////////////////////////////////
$slider-fixed-size-width: 80rem;

.slider-fixed-size {
  margin-right: calc(#{$slider-fixed-size-width} * -1 + 100%);
  .item {
    width: $slider-fixed-size-width; }
  @screen md {
    margin-right: calc(#{$slider-fixed-size-width} * -2 + 100%); }

  @screen xxl {
    margin-right: calc(#{$slider-fixed-size-width} * -3 + 100%); }

  @media (max-width: theme('screens.xs-max')) {
    margin-right: 0;
    .item {
      @apply w-full {}
      @apply overflow-hidden {}
      .item-link {
        width: 150%;
        margin-left: -25%; } } }
  &__button {
    @apply rounded-full {}
    max-width: 4.7rem;
    max-height: 4.7rem;
    @apply hidden {}
    padding: 1.2rem;
    @screen md {
      @apply block {}
      left: 3.5rem;
      bottom: 1.2rem; } } }


////////////////////
// SLIDER DETAIL
////////////////////////////////////////////////////////////////////////////////
$slider-detail-height: 50rem;
.slider-detail {
  .slide {
    display: block;
    height: $slider-detail-height;
    object-fit: cover;
    width: 100%; }
  .agile__actions {
    margin: 0; }
  .agile__nav-button {
    transform: translateY(-100%);
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 2.4rem;
    height: $slider-detail-height;
    position: absolute;
    top: 0;
    transition-duration: .3s;
    width: 8rem;
    &::before {
      display: none; }
    #{class-begin('fa')} {
      height: 5rem;
      width: 5rem;
      opacity: 0.5;
      border-radius: 50%;
      background-color: rgba($white, 0.75);
      &::before {
        position: absolute;
        transform: translate(-50%,-50%);
        top: 50%;
        left: 50%;
        color: rgba($black, 1); }
      &:hover {
        opacity: 1; } }
    &:hover {}

    &--prev {
      left: 0; }
    &--next {
      right: 0; } }

  .agile__dots {
    bottom: 0rem;
    left: 50%;
    position: relative;
    transform: translateX(-50%); }
  .agile__dot {
    margin: 0 0.2rem;
    height: 2rem;
    button {
      background-color: $white;
      opacity: 1;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      height: 1rem;
      font-size: 0;
      line-height: 0;
      margin: 0;
      padding: 0;
      transition-duration: .3s;
      width: 1rem; } }
  .agile__dot--current {
    button {
      background-color: $secondary; }
    &:hover {
      background-color: #fff; } } }

@media(max-width: theme('screens.md-max')) {
  .slide {
  	max-height: $slider-detail-height / 2; } }

////////////////////
// SLIDER SECONDARY
////////////////////////////////////////////////////////////////////////////////
.agile-secondary {
  overflow-x: hidden;
  background-color: $secondary;
  .quote-show {
    .agile {
      .agile__slides {
        max-height: 60rem; }
      .agile__actions {
        .agile__nav-button--prev {
          &:hover {
            cursor: url("../../frontend/images/icons/MOHOM-white-cursor-left.svg") 25 15, auto; } }
        .agile__nav-button--next {
          &:hover {
            cursor: url("../../frontend/images/icons/MOHOM-white-cursor-right.svg") 25 15, auto; } } }
      .agile__dots {
        left: 50%;
        top: 100%;
        position: absolute;
        transform: translate(-50%, -50%);
        margin-top: 5.5rem; }
      .agile__dot {
        margin: 0 0.5rem;
        height: 2rem;
        button {
          background-color: rgba($white, 0.5); } }
      .agile__dot--current {
        button {
          background-color: $white; } } } } }

@media(max-width: theme('screens.lg-max')) {
  .agile-secondary {
    background-color: $secondary;
    height: auto;
    padding: 0;
    .quote-show {
      .agile {
        .agile__dots {
          margin-top: -1rem; } } } } }

.agile--secondary {
  .agile__slides {
    @apply items-start {} } }
