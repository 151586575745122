// PROJECT - Component SHARED LINK - Studio HB 2017

////////////////////
// SHARED LINKS DEFAULT
////////////////////////////////////////////////////////////////////////////////

///// SHARED WRAPPER
.link-shared-wrapper {
  margin-bottom: 4rem;
  padding-top: 2rem;
  text-align: center;

  a {
    color: $gray;
    display: inline-block;
    line-height: 1.2;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    &:hover,
    &:focus,
    &:active {
      color: $primary; } } }
