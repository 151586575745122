// PROJECT - Component FORM DEFAULT - Studio HB 2017

////////////////////
// FORM BASE
////////////////////////////////////////////////////////////////////////////////
$input-height: 5rem;
$input-width: 100%;

///// FORM INPUT

input {
  background-color: $white-smooth; }
.form-control {
  background-color: $white-smooth !important;
  border: 0.2rem solid $primary;
  border-radius: 0;
  font-size: 1.5rem;
  padding: 1.5rem;
  background-color: $white;
  transition: all $transition-default;
  width: $input-width;
  @include placeholder {
    color: $primary;
    opacity: 1;
    transition: all $transition-default; } }

input.form-control {
  height: $input-height; }

///// FORM INPUT WHITE
.form-control--white {
  border: 0.2rem solid $white;
  background-color: $primary;
  @include placeholder {
    color: $white; } }

///// FORM GROUP
.form-group {
  margin-bottom: 2rem; }





////////////////////
// FORM LABEL
////////////////////////////////////////////////////////////////////////////////

///// FORM LABEL
.form-label {
  font-size: 1.5rem;
  line-height: 1.4;
  font-weight: 600; }



////////////////////
// FORM COMPONENT
////////////////////////////////////////////////////////////////////////////////

///// FORM SEPARATOR
.form-separator {
  margin: 2rem 0;
  position: relative;
  text-align: center;
  &::after {
    content: '';
    background-color: $gray-light;
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(50%);
    width: 100%;
    -webkit-filter: blur(0px); } // Fix blur issue

  span {
    background-color: $white;
    color: $gray-light;
    padding: 0 1.5rem;
    position: relative;
    z-index: 1; } }





////////////////////
// FORM INPUT + ICON
////////////////////////////////////////////////////////////////////////////////
$icon-input-padding: 5rem;


.form-icon-wrapper {
  position: relative;
  &:focus {
    #{class-begin('fa-')} {
      color: $gray; } }
  &.form-icon-wrapper--left {
    .form-control {
      padding-right: 0;
      padding-left: $icon-input-padding; }
    #{class-begin('fa-')} {
      left: 0;
      right: auto; } }

  /// Input
  .form-control {
    padding-right: $icon-input-padding;
    &:focus {
      + #{class-begin('fa-')} {
        color: $gray; } } }

  /// Icon
  #{class-begin('fa-')} {
    align-items: center;
    color: $gray-light;
    display: flex;
    font-size: 2.5rem;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    transition: $transition-default;
    width: 5rem; } }



////////////////////
// FORM INPUT TYPE CHECKBOX
////////////////////////////////////////////////////////////////////////////////


////////////////////
// FORM INPUT TYPE FILE
////////////////////////////////////////////////////////////////////////////////

///// LABEL WRAPPER
.custom-file {
  height: 5rem; // Height of the input
  &:hover {
    cursor: pointer;
    .custom-file-control {
      background-color: $primary;
      color: $white; } } }


///// INPUT
input.custom-file-input {
  height: 0; }


///// PLACEHOLDER
.custom-file-control {
  height: auto;
  right: auto;
  top: 50%;
  transform: translateY(-50%); }


.form__duo {
  display: grid;
  grid-template-columns: unquote("repeat(1, minmax(0, 1fr))");
  gap: 2rem;

  @screen lg {
    grid-template-columns: unquote("repeat(2, minmax(0, 1fr))"); } }
