$container: 90%;
$max-width: 120rem;

.reservation {
  background-color: $white-smooth;

  ////////////////////
  // HERO
  ////////////////////////////////////////////////////////////////////////////////
  &__hero {
    background-color: $white-smooth;
    position: relative;
    border-bottom: 1px solid rgba($secondary, 0.2);
    z-index: 0;

    > div {
      height: 9rem;

      @screen xl {
        @apply h-24 {} } } }

  &__cross {
    background-color: rgba($secondary, 0.2);
    transition: all $transition-default;
    @apply text-secondary {}

    &:hover {
      background-color: rgba($secondary, 1);
      @apply text-white {} } }

  &__logo {
    height: 2rem;

    @screen xl {
      @apply h-auto {} } }

  ////////////////////
  // COMMON - MENU
  ///////////////////////////////////////////
  &__menu-container {
    margin: 0 auto;

    .swiper-pagination {
      position: relative;
      display: flex;
      width: 100%;
      background-color: $white-smooth;
      border-bottom: 1px solid rgba($secondary, 0.2);
      z-index: 1;

      & > div {
        &::after {
          content: "\f054";
          @apply font-icon {}
          display: block;
          position: absolute;
          right: -7px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 1.5rem;
          font-weight: 200;

          @screen md {
            font-size: 2.5rem; } } }

      & > div:last-child {
        &::after {
          display: none; } } } }

  &__menu-icon {
    height: 3.6rem;
    width: 3.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 1px solid $primary;
    margin: 0 auto;

    @screen md {
      height: 3.6rem;
      margin-right: 1.5rem;
      margin-left: 0;
      margin-bottom: 0; } }

  &__menu-label {
    margin-top: 1rem;

    @screen md {
      margin-top: 0; } }

  &__menu-item {
    position: relative;
    text-align: center;
    font-size: 1.5rem;
    color: $primary;

    // Surcharge lib
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    width: 33.33% !important;
    height: 8.3rem !important;
    border-radius: 0 !important;
    background-color: transparent !important;

    @screen md {
      height: 6.3rem !important;
      flex-direction: row !important;
      justify-content: center !important;
      align-items: center !important; }

    & > div {
      display: flex;
      justify-content: center;
      align-items: center; }

    &.swiper-pagination-bullet {
      opacity: 0.5; }

    &.swiper-pagination-bullet-active {
      opacity: 1;

      .reservation__menu-label {
        color: $primary;
        font-weight: 700; }

      .reservation__menu-icon {
        color: $white-smooth;
        background-color: $secondary;
        border-color: $secondary; } }

    &.checked {
      opacity: 1;

      .reservation__menu-icon {
        & span::before {
          content: "\f00c"; } } } }

  &__slide {
    overflow-y: auto;
    height: calc(var(--app-height) - 176px);

    @screen md {
      height: calc(var(--app-height) - 127px); } }

  &__slide-container {
    width: $container;
    max-width: $max-width;
    margin: 0 auto; }

  ////////////////////
  // STEP 3 - INFO BLOC
  ///////////////////////////////////////////
  &-info {
    &__menu > p:nth-child(1) {
      @apply bg-secondary {}
      @apply text-white {} }

    &.active {
      .reservation-info__menu > p:nth-child(1) {
        @apply bg-transparent {}
        @apply text-primary {} }
      .reservation-info__menu > p:nth-child(2) {
        @apply bg-secondary {}
        @apply text-white {} } }


    &__payment {
      background-color: rgba($secondary, 0.1); } }

  ////////////////////
  // STEP 3 - PAYMENT BLOC
  ///////////////////////////////////////////
  &-payment {
    &__form {
      display: grid;
      grid-template-columns: unquote("repeat(1, minmax(0, 1fr))");
      gap: 2rem;

      @screen md {
        grid-template-columns: unquote("repeat(9, minmax(0, 1fr))"); } }

    &__form > div:nth-child(1),
    &__form > div:nth-child(4) {
      @screen md {
        grid-column: span 4 / span 4; } }

    &__form > div:nth-child(2) {
      @screen md {
        grid-column: span 3 / span 3; } }

    &__form > div:nth-child(3) {
      @screen md {
        grid-column: span 2 / span 2; } } }


  ////////////////////
  // THANK YOU BLOC
  ///////////////////////////////////////////
  &-thanks {
    > div {
      height: calc(var(--app-height) - 91px);

      @screen md {
        height: calc(var(--app-height) - 61px); } }

    &__container {
      max-width: 41rem;
      width: 90%;
      @apply mx-auto {} }

    &__logo {
      width: 54px;

      @screen md {
        width: 68px; } } } }


.datepicker--is-open {
  @media (max-width: 768px - 1px) {
    .reservation {
      &__menu-container {
        @apply hidden {} }

      &__slide-container,
      &__slide {
        @apply w-full {}
        @apply h-app-height {} }

      .swiper-wrapper {
        @apply h-app-height {} }

      .swiper-pagination {
        @apply opacity-0 {}
        @apply pointer-events-none {} } } } }
